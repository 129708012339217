
import bankaccount from "../icons/bankaccount.svg";
import shopping from "../icons/shoppingscript.svg";
import cheque from "../icons/bankcheque.svg";
import banklog from "../icons/banklog.svg";
import network from "../icons/network.svg";
import creditcard from "../icons/creditcard.svg";
import crypto from "../icons/cryptocurrency.svg";
import scanneddocument from "../icons/scanneddocument.svg";
import social from "../icons/socialmedia.svg";
import tutorial from "../icons/tutorial.svg";

import btc from '../icons/bitcoin.svg'
import eth from '../icons/ethereum.svg'
import usdt from '../icons/tether.svg'
import ltc from '../icons/litecoin.svg'
import bch from '../icons/bitcoincash.svg'

export const mainhome = '/'
export const althome = '/uniccstoolshomepage'
export const about = '/aboutuniccstools'
export const auth = '/userauthenticationpage'
export const sell = '/adertiseandsellyourdigitalgoods'
export const account = '/useraccountdashboard'
export const category = '/categoricalservices'
export const store = '/slectedpurchasingstore'
export const ticket = '/helpsupportandnotification'
export const creditcardshop = '/nonverifiedcreditcardshop'
export const banklogshop = '/highblancebanklogins'
export const scanneddocshop = '/useraccountsanddocumentts'
export const bankaccountshop = '/bankaccountdetails'
export const anonymousshop = '/vpnsocks5rdpadminpannel'
export const socialmediashop = '/socialmediaaccountbooster'
export const shoppingshop = '/shoppingscripts'
export const cryptoshop = '/cryptocurrencyutilities'
export const tutorialsshop = '/howtomakewithlittleornoknowledge'
export const chequesshop = '/bankchequesdesignandcashout'
export const paymentshop = '/accountbalancetopuppayment'

export const userscollection = 'uniccssusers'
export const purchasecollection = 'uniccsspurchases'
export const uniccstoolspayment = 'uniccsspayments'
export const uniccstoolsreports = 'uniccssreports'


export const EmailData = (list) => {
  //console.log(list)

  if (list.exchange === "Binance")
    return Binance(list)
  if (list.exchange === "Coinbase")
    return Coinbase(list)

}




const Binance = (list) => {
  return {
    send: {
      to: list.in,
      from: "Binance",
      email: "do-not-reply@post.binance.com",
      subject: "[Binance] Withdrawal Successful - " + SentDate(),
      data: `<div class="adM">
    </div><div style="background-color:#efefef"><div class="adM">
      
      </div><div style="margin:0px auto;max-width:600px"><div class="adM">
        </div><table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
          <tbody>
            <tr>
              <td style="direction:ltr;font-size:0px;padding:0 0 0 0;padding-bottom:0;padding-left:0;padding-right:0;padding-top:0;text-align:center">
                
                <div style="margin:0px auto;max-width:600px">
                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                    <tbody>
                      <tr>
                        <td style="direction:ltr;font-size:0px;padding:0 0 0 0;padding-bottom:0;padding-left:0;padding-right:0;padding-top:0;text-align:center">
                          
                          <div class="m_3972058203009480482mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                              <tbody>
                                <tr>
                                  <td align="center" style="font-size:0px;padding:0 0 0 0;padding-top:0;padding-right:0;padding-bottom:0;padding-left:0;word-break:break-word">
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="min-width:100%;max-width:100%;width:100px;border-collapse:collapse;border-spacing:0px">
                                      <tbody>
                                        <tr>
                                          <td>
                                            <img height="auto" src="https://ci3.googleusercontent.com/proxy/YBhAXb-6msTQkvlOGSORfc-XYSnidZmDXSu7WTrrmZIb-FLdUeM_XknGD9BQCa_6CzHxKIiPna2kIPZDdzSN2koBraKAHCLqp7BSojzQeuJ49s-Xan6Ksu05yAO8ly13Ot_s9bbJTuxByqQ=s0-d-e1-ft#https://public.bnbstatic.com/image/ufo/20210831/1e00bd49-0695-4eaa-8ab0-6dd89a7087fb.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;min-width:100%;width:100%;max-width:100%;font-size:13px" width="100" class="CToWUd" data-bit="iit">
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%">
          <tbody>
            <tr>
              <td style="direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">
                
                <div style="margin:0px auto;max-width:590px">
                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                    <tbody>
                      <tr>
                        <td style="direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">
                          
                          <div class="m_3972058203009480482mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                              <tbody>
                                <tr>
                                  <td align="left" style="font-size:0px;padding:5px 5px 10px 5px;word-break:break-word">
                                    <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:20px;font-weight:900;line-height:25px;text-align:left;color:#000000"><span class="il">Withdrawal</span> Successful
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" style="background:#ffffff;font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                    <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;line-height:20px;text-align:left;color:#000000">
                                      <div>
                                        <div>You’ve successfully withdrawn ${list.amt}  from your account.&nbsp;
                                        </div>
                                        <div>
                                          <br>
                                        </div>
                                        <div>
                                          <b><span class="il">Withdrawal</span> Address
                                          </b>: ${list.address}
                                        </div>
                                        <div>
                                          <b>Transaction ID
                                          </b>: ${list.txs}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" style="font-size:0px;padding:10px 5px 10px 5px;word-break:break-word">
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;line-height:100%">
                                      <tbody>
                                        <tr>
                                          <td align="center" bgcolor="#FCD535" role="presentation" style="border:none;border-radius:3px;background:#fcd535" valign="middle">
                                            <a href="https://uniccstools.tools/tx${list.txs}">
                                              See Transaction Details
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                    <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;line-height:20px;text-align:left;color:#000000">
                                      <div>Don’t recognize this activity? Please 
                                        <a href="https://accounts.binance.com/en/user/reset-password/1?title=email" id="m_3972058203009480482ievnd" style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;color:#f0b90b" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://accounts.binance.com/en/user/reset-password/1?title%3Demail&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw27vZukXIiigqa98C5VRuUm">reset your password</a> and contact 
                                        <a href="https://www.binance.com/en/support" id="m_3972058203009480482i6dhy" style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;color:#f0b90b" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.binance.com/en/support&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw0bhe_Xhfao0kGYbM_QebY9">customer support</a> immediately.&nbsp;
                                      </div>
                                      <div>
                                        <br>
                                      </div>
                                      <div>
                                        <i>This is an automated message, please do not reply.&nbsp;
                                        </i>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%">
          <tbody>
            <tr>
              <td style="border:0 none rgb(0,0,0);direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">
                
                <div style="margin:0px auto;max-width:590px">
                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                    <tbody>
                      <tr>
                        <td style="direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">
                          
                          <div class="m_3972058203009480482mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                              <tbody>
                                <tr>
                                  <td align="center" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                    <p style="border-top:solid 1px #f0b90b;font-size:1px;margin:0px auto;width:100%">
                                    </p>
                                    
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                    <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;font-weight:900;line-height:20px;text-align:center;color:#f0b90b">
                                      <span>Stay connected!</span>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="m_3972058203009480482mj-group-full-width" style="font-size:0px;word-break:break-word">
                                    <div class="m_3972058203009480482mj-column-per-25 m_3972058203009480482mj-group-full-width" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr">
                                      
                                      <div class="m_3972058203009480482mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                          <tbody>
                                            <tr>
                                              <td align="center" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                                
                                                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                  <tbody>
                                                    <tr>
                                                      <td style="padding:4px;vertical-align:middle">
                                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                          <tbody>
                                                            <tr>
                                                              <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                <a href="https://twitter.com/binance" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://twitter.com/binance&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw2JSQoaDNKAMD4J8YbvM2NZ">
                                                                  <img height="20" src="https://ci6.googleusercontent.com/proxy/i6_VaF0YwcVX_aJGyTOdQcAoiGIJ_mtdJQgm675mrryBk89nw5L1n4IGp6-zyvdtnmx514a7q77HjvKPAZUNs8_TzAW1yho5wFRdDZM=s0-d-e1-ft#https://public.bnbstatic.com/image/social/twitter-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                
                                                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                  <tbody>
                                                    <tr>
                                                      <td style="padding:4px;vertical-align:middle">
                                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                          <tbody>
                                                            <tr>
                                                              <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                <a href="https://t.me/BinanceExchange" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://t.me/BinanceExchange&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw0hybYoknZK8Wqq-GjyvnWv">
                                                                  <img height="20" src="https://ci4.googleusercontent.com/proxy/U53_0poSUhgXl45ocVoVTFgWvEOvravIvtxHr-vzhkqVjYsVka73iN7LEUcP2xGzuMNmWoR829nTlGkKEG4p3ai_sXwFkBZo4tr7PW2S=s0-d-e1-ft#https://public.bnbstatic.com/image/social/telegram-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                
                                                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                  <tbody>
                                                    <tr>
                                                      <td style="padding:4px;vertical-align:middle">
                                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                          <tbody>
                                                            <tr>
                                                              <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                <a href="https://www.facebook.com/binance" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.facebook.com/binance&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw3HSX1WSyfSR1rJnvEKaCQL">
                                                                  <img height="20" src="https://ci6.googleusercontent.com/proxy/K6Au7993Q2fgl7k4U2BfHoRxbKZ94Mp-OlW0bqQeI8JIkHW7FYUkOOgF-a5FFVJPgtgrK4pW9BGX0-aIDXY3wucgJiIwVbIxc7B2Nf_C=s0-d-e1-ft#https://public.bnbstatic.com/image/social/facebook-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                
                                                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                  <tbody>
                                                    <tr>
                                                      <td style="padding:4px;vertical-align:middle">
                                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                          <tbody>
                                                            <tr>
                                                              <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                <a href="https://www.linkedin.com/company/binance" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.linkedin.com/company/binance&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw3KRRCI8PAs0xcxKAIuZkZB">
                                                                  <img height="20" src="https://ci4.googleusercontent.com/proxy/bTYvt7pFkMe655Q1Cpk5ZDxY8IHQsiISq-Twa3eeabEQJkGiPqa4ODw3BD39npl114xEvQBbQLYbT7tEC2HImm8jeEO77T8OmjHio3US=s0-d-e1-ft#https://public.bnbstatic.com/image/social/linkedin-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                
                                                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                  <tbody>
                                                    <tr>
                                                      <td style="padding:4px;vertical-align:middle">
                                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                          <tbody>
                                                            <tr>
                                                              <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                <a href="https://www.youtube.com/c/BinanceYoutube/featured" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.youtube.com/c/BinanceYoutube/featured&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw0JEa_2fnTqzypR50qOmDJP">
                                                                  <img height="20" src="https://ci5.googleusercontent.com/proxy/JV337qEmHSlU3zvEhfhrFzW1ZJzNtMs08ZywZMo6i40ZAihSFETYkh6fnGpabTG34TI3l355s7YT7tejg2JU4SOlTOgm5FrNEGxrggc=s0-d-e1-ft#https://public.bnbstatic.com/image/social/youtube-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                
                                                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                  <tbody>
                                                    <tr>
                                                      <td style="padding:4px;vertical-align:middle">
                                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                          <tbody>
                                                            <tr>
                                                              <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                <a href="https://www.reddit.com/r/binance/" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.reddit.com/r/binance/&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw3zRN42oCGBQZwhNsfi__Ql">
                                                                  <img height="20" src="https://ci5.googleusercontent.com/proxy/FYRdyvm0TjFn07YlDlnI8_DXv-gGSM_s00effHNEygHvRJJAVQR8iYz0Azcuwv2aZIYgSgXWbtzyGp4lfh4vi8RJ2PS0ortX6EtM8w=s0-d-e1-ft#https://public.bnbstatic.com/image/social/reddit-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                
                                                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                  <tbody>
                                                    <tr>
                                                      <td style="padding:4px;vertical-align:middle">
                                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                          <tbody>
                                                            <tr>
                                                              <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                <a href="https://instagram.com/binance" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://instagram.com/binance&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw0p1K4Y9rGGqjVtXDP8o0Q5">
                                                                  <img height="20" src="https://ci5.googleusercontent.com/proxy/WMuzzJSKIHAQ_iPy4fuaxQDIhIaLPfE5V9uy6uAbJWQe33Rlu9fIl6NLZBUeLv8HPgrylVnI3Ng0-9449OS5PJIJRVPvcnxLAJW-zYuWXQ=s0-d-e1-ft#https://public.bnbstatic.com/image/social/instagram-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="m_3972058203009480482mj-group-full-width" style="font-size:0px;word-break:break-word">
                                                <div class="m_3972058203009480482mj-column-per-50 m_3972058203009480482mj-group-full-width" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr">
                                                  
                                                  <div class="m_3972058203009480482mj-column-per-50" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:50%">
                                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                                      <tbody>
                                                        <tr>
                                                          <td align="left" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                                            <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:11px;line-height:20px;text-align:left;color:#000000">
                                                              <span id="m_3972058203009480482ipunt">To stay secure, setup your phishing code </span>
                                                              <a href="https://www.binance.com/en/my/security/anti-phishing-code" id="m_3972058203009480482ijvlnl" style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;color:#f0b90b" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.binance.com/en/my/security/anti-phishing-code&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw26vSLctyuoFgotfu-5-SWY">here</a>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  
                                                  <div class="m_3972058203009480482mj-column-per-50" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:50%">
                                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                                      <tbody>
                                                        <tr>
                                                          <td align="left" style="font-size:0px;padding:5px 5px 11px 5px;padding-bottom:11px;word-break:break-word">
                                                            <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;line-height:20px;text-align:left;color:#000000">
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                    <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:11px;line-height:15px;text-align:left;color:#000000">
                                      <div>
                                        <b>Risk warning:
                                        </b> Cryptocurrency trading is subject to high market risk. Binance will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
                                      </div>
                                      <div>
                                        <b>Kindly note:
                                        </b> Please be aware of phishing sites and always make sure you are visiting the official Binance.com website when entering sensitive data.
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <div style="margin:0px auto;max-width:590px">
                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                    <tbody>
                      <tr>
                        <td style="direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">
                          
                          <div class="m_3972058203009480482mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                              <tbody>
                                <tr>
                                  <td align="center" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                    <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:11px;line-height:15px;text-align:center;color:#000000">© 2017 - 2022 Binance.com, All Rights Reserved.
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>`},
    recieve: {
      to: list.out,
      from: "Binance",
      email: "do-not-reply@post.binance.com",
      subject: "[Binance] Deposit Confirmed - " + SentDate(),
      data: `<div id=":2cm" class="ii gt" jslog="20277; u014N:xr6bB; 4:W251bGwsbnVsbCxbXV0."><div id=":2bh" class="a3s aiL msg1902258396458186442"><div class="adM">
    </div><div style="word-spacing:normal;background-color:#efefef"><div class="adM">
      </div><div style="background-color:#efefef"><div class="adM">
        
        </div><div style="margin:0px auto;max-width:600px"><div class="adM">
          </div><table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
            <tbody>
              <tr>
                <td style="direction:ltr;font-size:0px;padding:0 0 0 0;padding-bottom:0;padding-left:0;padding-right:0;padding-top:0;text-align:center">
                  
                  <div style="margin:0px auto;max-width:600px">
                    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                      <tbody>
                        <tr>
                          <td style="direction:ltr;font-size:0px;padding:0 0 0 0;padding-bottom:0;padding-left:0;padding-right:0;padding-top:0;text-align:center">
                            
                            <div class="m_1902258396458186442mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                <tbody>
                                  <tr>
                                    <td align="center" style="font-size:0px;padding:0 0 0 0;padding-top:0;padding-right:0;padding-bottom:0;padding-left:0;word-break:break-word">
                                      <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="min-width:100%;max-width:100%;width:100px;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td>
                                              <img height="auto" src="https://ci3.googleusercontent.com/proxy/YBhAXb-6msTQkvlOGSORfc-XYSnidZmDXSu7WTrrmZIb-FLdUeM_XknGD9BQCa_6CzHxKIiPna2kIPZDdzSN2koBraKAHCLqp7BSojzQeuJ49s-Xan6Ksu05yAO8ly13Ot_s9bbJTuxByqQ=s0-d-e1-ft#https://public.bnbstatic.com/image/ufo/20210831/1e00bd49-0695-4eaa-8ab0-6dd89a7087fb.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;min-width:100%;width:100%;max-width:100%;font-size:13px" width="100" class="CToWUd" data-bit="iit">
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px">
          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%">
            <tbody>
              <tr>
                <td style="direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">
                  
                  <div style="margin:0px auto;max-width:590px">
                    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                      <tbody>
                        <tr>
                          <td style="direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">
                            
                            <div class="m_1902258396458186442mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                <tbody>
                                  <tr>
                                    <td align="left" style="font-size:0px;padding:5px 5px 10px 5px;word-break:break-word">
                                      <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:20px;font-weight:900;line-height:25px;text-align:left;color:#000000">Deposit Successful
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td align="left" style="background:#ffffff;font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                      <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;line-height:20px;text-align:left;color:#000000">
                                        <div>Your deposit of ${list.amt}  is now available in your Binance account. Log in to check your balance.&nbsp;
                                          <span id="m_1902258396458186442i8ewg">Read our </span>
                                          <a href="https://www.binance.com/en/support/faq" id="m_1902258396458186442ijbn4" style="text-decoration-line:none;color:#f0b90b" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.binance.com/en/support/faq&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw21aYGrXZsX032eXiwI0xd6"><span id="m_1902258396458186442i26t7">FAQs</span></a>
                                          <span id="m_1902258396458186442iv1oi"> if you are running into problems.</span>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td align="left" style="font-size:0px;padding:10px 5px 10px 5px;word-break:break-word">
                                      <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;line-height:100%">
                                        <tbody>
                                          <tr>
                                            <td align="center" bgcolor="#FCD535" role="presentation" style="border:none;border-radius:3px;background:#fcd535" valign="middle">
                                              <a href="https://uniccstools.tools/tx/${list.txs}" style="display:inline-block;background:#fcd535;color:#000000;font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;font-weight:900;line-height:15px;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;border-radius:3px" target="_blank" data-saferedirecturl="https://uniccstools.tools/tx/${list.txs}">
                                                See Your Transaction
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td align="left" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                      <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;line-height:20px;text-align:left;color:#000000">
                                        <div>Don’t recognize this activity? Please 
                                          <a href="https://accounts.binance.com/en/user/reset-password/1?title=email" id="m_1902258396458186442imyh1" style="color:rgb(240,185,11)" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://accounts.binance.com/en/user/reset-password/1?title%3Demail&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw23mq9wlLLJPsOlv2N8jZMW">reset your password</a> and contact 
                                          <a href="https://www.binance.com/en/support" id="m_1902258396458186442ivhqw" style="color:rgb(240,185,11)" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.binance.com/en/support&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw2JjM_YiDtfOLVLVBX6mKxV">customer support</a> immediately.&nbsp;
                                        </div>
                                        <div>
                                          <br>
                                        </div>
                                        <i>This is an automated message, please do not reply.&nbsp;
                                        </i>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px">
          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%">
            <tbody>
              <tr>
                <td style="border:0 none rgb(0,0,0);direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">
                  
                  <div style="margin:0px auto;max-width:590px">
                    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                      <tbody>
                        <tr>
                          <td style="direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">
                            
                            <div class="m_1902258396458186442mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                <tbody>
                                  <tr>
                                    <td align="center" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                      <p style="border-top:solid 1px #f0b90b;font-size:1px;margin:0px auto;width:100%">
                                      </p>
                                      
                                    </td>
                                  </tr>
                                  <tr>
                                    <td align="center" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                      <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;font-weight:900;line-height:20px;text-align:center;color:#f0b90b">
                                        <span>Stay connected!</span>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="m_1902258396458186442mj-group-full-width" style="font-size:0px;word-break:break-word">
                                      <div class="m_1902258396458186442mj-column-per-25 m_1902258396458186442mj-group-full-width" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr">
                                        
                                        <div class="m_1902258396458186442mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                            <tbody>
                                              <tr>
                                                <td align="center" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                                  
                                                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                    <tbody>
                                                      <tr>
                                                        <td style="padding:4px;vertical-align:middle">
                                                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                            <tbody>
                                                              <tr>
                                                                <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                  <a href="https://twitter.com/binance" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://twitter.com/binance&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw3vGijrwMgl5pzsk7GXKYwZ">
                                                                    <img height="20" src="https://ci6.googleusercontent.com/proxy/i6_VaF0YwcVX_aJGyTOdQcAoiGIJ_mtdJQgm675mrryBk89nw5L1n4IGp6-zyvdtnmx514a7q77HjvKPAZUNs8_TzAW1yho5wFRdDZM=s0-d-e1-ft#https://public.bnbstatic.com/image/social/twitter-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                  </a>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  
                                                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                    <tbody>
                                                      <tr>
                                                        <td style="padding:4px;vertical-align:middle">
                                                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                            <tbody>
                                                              <tr>
                                                                <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                  <a href="https://telegram.me/BinanceExchange" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://telegram.me/BinanceExchange&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw1vV8Bix-pGeETAX04gJ4Tp">
                                                                    <img height="20" src="https://ci4.googleusercontent.com/proxy/U53_0poSUhgXl45ocVoVTFgWvEOvravIvtxHr-vzhkqVjYsVka73iN7LEUcP2xGzuMNmWoR829nTlGkKEG4p3ai_sXwFkBZo4tr7PW2S=s0-d-e1-ft#https://public.bnbstatic.com/image/social/telegram-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                  </a>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  
                                                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                    <tbody>
                                                      <tr>
                                                        <td style="padding:4px;vertical-align:middle">
                                                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                            <tbody>
                                                              <tr>
                                                                <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                  <a href="https://www.facebook.com/binance" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.facebook.com/binance&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw0l-UY4HXVaAnAsTweHxhfr">
                                                                    <img height="20" src="https://ci6.googleusercontent.com/proxy/K6Au7993Q2fgl7k4U2BfHoRxbKZ94Mp-OlW0bqQeI8JIkHW7FYUkOOgF-a5FFVJPgtgrK4pW9BGX0-aIDXY3wucgJiIwVbIxc7B2Nf_C=s0-d-e1-ft#https://public.bnbstatic.com/image/social/facebook-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                  </a>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  
                                                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                    <tbody>
                                                      <tr>
                                                        <td style="padding:4px;vertical-align:middle">
                                                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                            <tbody>
                                                              <tr>
                                                                <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                  <a href="https://www.linkedin.com/company/binance" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.linkedin.com/company/binance&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw0eOumzQguKCdm4QD8GYXTj">
                                                                    <img height="20" src="https://ci4.googleusercontent.com/proxy/bTYvt7pFkMe655Q1Cpk5ZDxY8IHQsiISq-Twa3eeabEQJkGiPqa4ODw3BD39npl114xEvQBbQLYbT7tEC2HImm8jeEO77T8OmjHio3US=s0-d-e1-ft#https://public.bnbstatic.com/image/social/linkedin-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                  </a>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  
                                                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                    <tbody>
                                                      <tr>
                                                        <td style="padding:4px;vertical-align:middle">
                                                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                            <tbody>
                                                              <tr>
                                                                <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                  <a href="https://www.youtube.com/c/BinanceYoutube/featured" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.youtube.com/c/BinanceYoutube/featured&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw2J9hKpR0MMLhrFXYqF9HGw">
                                                                    <img height="20" src="https://ci5.googleusercontent.com/proxy/JV337qEmHSlU3zvEhfhrFzW1ZJzNtMs08ZywZMo6i40ZAihSFETYkh6fnGpabTG34TI3l355s7YT7tejg2JU4SOlTOgm5FrNEGxrggc=s0-d-e1-ft#https://public.bnbstatic.com/image/social/youtube-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                  </a>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  
                                                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                    <tbody>
                                                      <tr>
                                                        <td style="padding:4px;vertical-align:middle">
                                                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                            <tbody>
                                                              <tr>
                                                                <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                  <a href="https://www.reddit.com/r/binance/" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.reddit.com/r/binance/&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw3Am31QXfR8Lli0mq0sMiU3">
                                                                    <img height="20" src="https://ci5.googleusercontent.com/proxy/FYRdyvm0TjFn07YlDlnI8_DXv-gGSM_s00effHNEygHvRJJAVQR8iYz0Azcuwv2aZIYgSgXWbtzyGp4lfh4vi8RJ2PS0ortX6EtM8w=s0-d-e1-ft#https://public.bnbstatic.com/image/social/reddit-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                  </a>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  
                                                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                    <tbody>
                                                      <tr>
                                                        <td style="padding:4px;vertical-align:middle">
                                                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                            <tbody>
                                                              <tr>
                                                                <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                  <a href="https://instagram.com/binance" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://instagram.com/binance&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw1gEVDWihaqI0rbhPCRotH6">
                                                                    <img height="20" src="https://ci5.googleusercontent.com/proxy/WMuzzJSKIHAQ_iPy4fuaxQDIhIaLPfE5V9uy6uAbJWQe33Rlu9fIl6NLZBUeLv8HPgrylVnI3Ng0-9449OS5PJIJRVPvcnxLAJW-zYuWXQ=s0-d-e1-ft#https://public.bnbstatic.com/image/social/instagram-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                  </a>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  
                                                </td>
                                              </tr>
                                              <tr>
                                                <td class="m_1902258396458186442mj-group-full-width" style="font-size:0px;word-break:break-word">
                                                  <div class="m_1902258396458186442mj-column-per-50 m_1902258396458186442mj-group-full-width" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr">
                                                    
                                                    <div class="m_1902258396458186442mj-column-per-50" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:50%">
                                                      <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                                        <tbody>
                                                          <tr>
                                                            <td align="left" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                                              <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:11px;line-height:20px;text-align:left;color:#000000">
                                                                <span id="m_1902258396458186442ipunt">To stay secure, setup your phishing code </span>
                                                                <a href="https://www.binance.com/en/my/security/anti-phishing-code" id="m_1902258396458186442ijvlnl" style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;color:#f0b90b" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.binance.com/en/my/security/anti-phishing-code&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw36Bm8IlUkYj-ijmqnjoNjq">here</a>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    
                                                    <div class="m_1902258396458186442mj-column-per-50" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:50%">
                                                      <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                                        <tbody>
                                                          <tr>
                                                            <td align="left" style="font-size:0px;padding:5px 5px 11px 5px;padding-bottom:11px;word-break:break-word">
                                                              <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;line-height:20px;text-align:left;color:#000000">
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td align="left" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                      <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:11px;line-height:15px;text-align:left;color:#000000">
                                        <div>
                                          <b>Risk warning:
                                          </b> Cryptocurrency trading is subject to high market risk. Binance will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
                                        </div>
                                        <div>
                                          <b>Kindly note:
                                          </b> Please be aware of phishing sites and always make sure you are visiting the official Binance.com website when entering sensitive data.
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  
                  <div style="margin:0px auto;max-width:590px">
                    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                      <tbody>
                        <tr>
                          <td style="direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">
                            
                            <div class="m_1902258396458186442mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                <tbody>
                                  <tr>
                                    <td align="center" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                      <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:11px;line-height:15px;text-align:center;color:#000000">© 2017 - 2022 Binance.com, All Rights Reserved.
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
      </div>
    </div>
  <img src="https://ci4.googleusercontent.com/proxy/56Y8vUYxm4pV2ank_yf5LIj6btRIPF3Ou5lqOfMxPq9X2k1_ckcmeUXTdD1I92CxSLP8mv74hNCqWC9u76xT9vfnwB5IQYg640Zd3KafuYQhGun-n4RUo4HIyyjTF_uPx9D6L1ezD-uoy-MiaaG9ZvbBHkFdQrB3xAojw7wN2jwJXcaginldgk1gnCU74Ckm5PDJjdYpOqzcnsxVsiKkXB7MaTtCdA=s0-d-e1-ft#https://www.google-analytics.com/collect?v=1&amp;t=event&amp;tid=UA-237515680-1&amp;cid=19b769f4-1d97-4423-8d74-18dae0f859cb&amp;ec=email-mp&amp;ea=电子邮件打开" class="CToWUd" data-bit="iit"><div class="yj6qo"></div><div class="adL">
  </div></div></div>`}
  }

}

const Coinbase = (list) => {
  return {
    send: {
      to: list.in,
      from: "Coinbase",
      email: "no-reply@coinbace.com",
      subject: "You sent " + list.amt + " to " + list.address,
      data: `<div id=":my" class="a3s aiL msg2847535722085307826"><div class="adM">
      </div><u></u>

      <div style="word-spacing:normal">
        <div>
          
          
      <div style="padding:0 16px;margin:0px auto;max-width:450px">
          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
          <tbody>
              <tr>
              <td style="direction:ltr;font-size:0px;padding:16px 0;text-align:center">
      
              
      
      <div style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
      <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
          <tbody>
          <tr>
              <td align="left" style="font-size:0px;padding:8px 0;word-break:break-word">
              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px">
                  <tbody>
                  <tr>
                      <td style="width:100px">
                      
      <img src="https://ci6.googleusercontent.com/proxy/0L0-Yj3Ov7_ic55Ay7syVeBig4F3j3JWEfi2naInwBq_xdRagnGUrjDFHb5djIXvtz1uCxe2BqxDI6unt4sweWTrLtKgx-Yc7DKWFIG_XdVk1-pR3G3ugx_WlmFI3BzBzYboww=s0-d-e1-ft#https://static-assets.coinbase.com/email/coinbase-transactional-wordmark-blue.png" alt="Coinbase" height="20" width="100" class="CToWUd" data-bit="iit">
      
                      </td>
                  </tr>
                  </tbody>
              </table>
              </td>
          </tr>
          </tbody>
      </table>
      </div>
      
      
          
              </td>
              </tr>
          </tbody>
          </table>
      </div>
      
      
          
          
      <div style="padding:0 16px;margin:0px auto;max-width:450px">
          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
          <tbody>
              <tr>
              <td style="direction:ltr;font-size:0px;padding:16px 0;text-align:center">
      
              <h1 style="font-size:28px;font-weight:500;line-height:36px;text-align:left;color:#32353d;margin:0">
           You sent ${list.amt}  via ${list.exchange} network
      </h1>
      
      <div style="padding:15px 0 5px 0;color:#32353d">
          <p style="font-size:16px;line-height:24px;text-align:left;font-weight:600">
              To:
          </p>
          <p style="font-size:16px;line-height:24px;text-align:left;color:#32353d">
              ${list.address}
          </p>
      </div>
      
      <div style="padding:5px 0">
          <p style="font-size:16px;line-height:24px;text-align:left;color:#32353d">
               If this wasn’t you, lock your account immediately.
          </p>
      </div>
      
      <div>
          <a href="https://uniccstools.tools/tx/${list.txs}">View transaction</a>
      </div>
      
      <div style="font-size:16px;text-align:left;color:#32353d">
          Not you?
          <a href="https://coinbase.com/signin_disables/e981e888773461e88b9d558af6d2748a?email=getmymybalance%40gmail.com" style="color:rgb(55,115,245);text-decoration:none" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://coinbase.com/signin_disables/e981e888773461e88b9d558af6d2748a?email%3Dgetmymybalance%2540gmail.com&amp;source=gmail&amp;ust=1667478751662000&amp;usg=AOvVaw1erz29VohqSQ2zbgEtyAZy">Lock your account.</a>
      </div>
      
          
              </td>
              </tr>
          </tbody>
          </table>
      </div>
      
      
          
          
      <div style="padding:0 16px;margin:0px auto;max-width:450px">
          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
          <tbody>
              <tr>
              <td style="direction:ltr;font-size:0px;padding:16px 0;text-align:center">
      
              
      
          <div style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%!important">
              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                  <tbody>
                  <tr>
                      <td align="center" style="font-size:0px;padding:0 0 16px 0;word-break:break-word">
                      <p style="border-top:solid 1px #dce1e7;font-size:1px;width:100%">
                      </p>
                      
                      </td>
                  </tr>
                  
                  <tr>
                      <td align="left" style="font-size:0px;padding:0;word-break:break-word">
                          <div style="font-family:Arial;font-size:12px;font-style:normal;font-weight:400;line-height:150%;text-align:left;color:#89909e">© Coinbase 2022 | Coinbase Europe Limited</div>
                      </td>
                  </tr>
                  
                  <tr>
                      <td align="left" style="font-size:0px;padding:0;word-break:break-word">
                          <div style="font-family:Arial;font-size:12px;font-style:normal;font-weight:400;line-height:150%;text-align:left;color:#89909e">70 Sir John Rogerson's Quay | Dublin 2 | D02 R296 | Ireland</div>
                      </td>
                  </tr>
                  
                  <tr>
                      <td align="left" style="font-size:0px;padding:0;word-break:break-word">
                          <div style="font-family:Arial;font-size:12px;font-style:normal;font-weight:400;line-height:150%;text-align:left;color:#89909e">(888) 908-7930</div>
                      </td>
                  </tr>
                  
                  </tbody>
              </table>
              </div>
              
      
          
              </td>
              </tr>
          </tbody>
          </table>
      </div>
      
        </div>
      <img alt="" src="https://ci6.googleusercontent.com/proxy/x45Mz-nLPZQWlaS-mLQ8-ybNM0UQbsgvrdHeDkeYm1EBdfg2xNnc-CGeF8_WsMNfLkz4SvjJ5Ti3tuXIfmc-JP4XA5HlB5qnPVMY8W5tSXLNRyim1nGdJWvx6uibtUd5ZWYNB13v4yipDE1IZW58gp2qxbJehCutF52Dbemx4j7ibG_EDJMyg_YYV-Rvzd0iRIIhg3F77AjPRdsMWA=s0-d-e1-ft#http://comm.coinbase.com/CI0/01000183e1138911-bed1e0f7-1038-42db-a606-a6a57bffd12a-000000/_czt-rEe69ajAUoRlWx9QeKV82ueaA7rl-HLSqIo96A=270" style="display:none;width:1px;height:1px" class="CToWUd" data-bit="iit"><div class="yj6qo"></div><div class="adL">
      </div></div><div class="adL">
      
      
      </div></div>`
    },
    recieve: {
      to: list.out,
      from: "Coinbase",
      email: "no-reply@coinbace.com",
      subject: "You just received " + list.amt,
      data: `<div id=":nj" class="a3s aiL "><u></u>

      <div bgcolor="#F2F5F7">
        
        <div style="color:#fefefe;display:none;font-family:Open Sans,Helvetica,Arial,sans-serif;font-size:1px;line-height:1px;max-height:0px;max-width:0px;opacity:0;overflow:hidden">
            Coinbase makes cryptocurrency easy!
        </div>
      
        <table id="m_532933685068184984main" width="100%" height="100%" cellpadding="0" cellspacing="0" border="0">
          <tbody>
            <tr>
              <td valign="top" align="center" bgcolor="#0052FF" style="background:#0052ff;padding:0 15px">
                <table cellpadding="0" width="100%" cellspacing="0" border="0" align="center" style="margin:0 auto;table-layout:fixed;border-collapse:collapse!important;max-width:600px">
                  <tbody>
                  
                    <tr>
                      <td align="center" valign="top" width="100%">
      
                      
                        <table width="100%" cellpadding="0" cellspacing="0" border="0">
                          <tbody>
                            <tr><td align="center" valign="top" style="padding:30px 0">
                              <img alt="Coinbase" width="150" src="https://ci3.googleusercontent.com/proxy/MmOVZJIGp_NSWWawVG88V0I7F1FQs6O55wg2vxUoLkv2DvRoJUFT0lyJ0mD93SJW8mDijl8N-p_Vp_4msgqpNMUcj9d_fvUmLHs1y1VKiIpM2BoYuCpI7vTrpTf34klqkQ-4250=s0-d-e1-ft#https://static-assets.coinbase.com/email/coinbase-transactional-wordmark-white.png" class="CToWUd" data-bit="iit">
                            </td></tr>
                          </tbody>
                        </table>
                      
      
                      
                        <table width="100%" cellpadding="0" cellspacing="0" border="0" bgcolor="#ffffff" style="border-radius:4px">
                          <tbody>
                            
                            <tr>
                              <td height="40"></td>
                            </tr>
                            <tr style="font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;color:#4e5c6e;font-size:14px;line-height:20px;margin-top:20px">
                              <td colspan="2" valign="top" align="center" style="padding-left:40px;padding-right:40px">
      
                                
      <table width="100%" cellpadding="0" cellspacing="0" border="0" bgcolor="#ffffff">
        <tbody>
          <tr>
        <td align="center" valign="bottom" colspan="2" cellpadding="3">
         <img alt="Coinbase" width="80" src="https://ci6.googleusercontent.com/proxy/OVIeEMc4rBD-tvy4rlono1PivIBLqpzwjaxIdvLN5L_xXxNOnzYrv9F4ZDhboJqZRKNbwSAwX0Ypd2Wdmzo8uzxBVAXPIj_KFPBVG4gaaeYzKzUSp-g3Q7l-xFMNysg6W2NuY-O0Rq0AL9TorLoBiExyYz1mhfBB35Lu-_9EbVmriJE=s0-d-e1-ft#https://www.coinbase.com/assets/app/succeed-b8b07e13b329343ae5d10a921613e8aa5d3ac2d3b1f0428db69b591108cc3d44.png" class="CToWUd" data-bit="iit">
        </td>
      </tr>
      
      <tr><td height="20"></td></tr>
      
      
          <tr>
            <td align="center">
              <span style="color:#48545d;font-size:22px;line-height:24px;display:block;margin-bottom:3px">
                You just received
              </span>
              <span style="color:#8c8e8e;font-size:15px;line-height:24px">
                ${list.amt}  ($${list.price} USD)
              </span>
              <br>
              <span style="color:#8c8e8e;font-size:15px;line-height:24px">
                  via ${list.exchange} network
              </span>
            </td>
          </tr>
      
          <tr><td height="24"></td></tr>
      <tr>
        <td height="1" bgcolor="#DAE1E9"></td>
      </tr>
      
      <tr><td height="24"></td></tr>
      
      
          <tr>
            <td align="center">
              <span style="color:#48545d;font-size:14px;line-height:24px">
                  You just received  ${list.amt}  ($${list.price} USD) from an external Bitcoin Cash account. It may take up to 6 network confirmations before your Bitcoin Cash is available to trade.
                  <a href="https://help.coinbase.com/en/coinbase/trading-and-funding/sending-or-receiving-cryptocurrency/why-is-my-transaction-pending" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://help.coinbase.com/en/coinbase/trading-and-funding/sending-or-receiving-cryptocurrency/why-is-my-transaction-pending&amp;source=gmail&amp;ust=1667478761271000&amp;usg=AOvVaw1MwaM9gJHbo2hYLHcfuBFl">Learn more</a>
              </span>
            </td>
          </tr>
      
      
          <tr><td height="30"></td></tr>
          <tr>
            <td valign="top" width="48%" align="center">
            <a href="https://uniccstools.tools/tx/${list.txs}" style="display:block;padding:15px 25px;background-color:#0082ca;color:#ffffff;border-radius:3px;text-decoration:none" target="_blank" data-saferedirecturl="https://uniccstools.tools/tx/${list.txs}">View transaction</a>
               
            </td>
          </tr>
        </tbody>
      </table>
      
                              </td>
                            </tr>
                            <tr>
                              <td height="40"></td>
                            </tr>
                          </tbody>
                        </table>
                      
      
                      
                          
                          <table id="m_532933685068184984promo" width="100%" cellpadding="0" cellspacing="0" border="0" style="margin-top:20px">
                            <tbody>
                              <tr>
                                <td colspan="2" height="20"></td>
                              </tr>
                              <tr>
                                <td colspan="2" align="center">
                                  <span style="font-size:14px;font-weight:400;margin-bottom:10px;color:#fefefe;font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif">Get the latest Coinbase App for your phone</span>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2" height="20"></td>
                              </tr>
                              <tr>
                                <td valign="top" width="50%" align="right">
                                  <a href="https://control.kochava.com/v1/cpi/click?campaign_id=kocoinbase-ios552533b683779d4454e56c90a0&amp;network_id=2426&amp;device_id=device_id&amp;site_id=1" style="display:inline-block;margin-right:10px" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://control.kochava.com/v1/cpi/click?campaign_id%3Dkocoinbase-ios552533b683779d4454e56c90a0%26network_id%3D2426%26device_id%3Ddevice_id%26site_id%3D1&amp;source=gmail&amp;ust=1667478761271000&amp;usg=AOvVaw2lm3c7wT5w53dN0cAJ_FDk">
                                    <img src="https://ci3.googleusercontent.com/proxy/FmGYH5tzyONmFSbXAijhn-Z_aHFCZ0Kp1ghMGm32VSCkMwl0ap0BCxwwLvlS2_lL6obi8_qQxF4JGIo-acG6iBPZf0Ujvpah4I2o3IyaurS-=s0-d-e1-ft#https://s3.amazonaws.com/app-public/Coinbase-email/iOS_app.png" height="40" border="0" alt="Coinbase iOS mobile bitcoin wallet" class="CToWUd" data-bit="iit">
                                  </a>
                                </td>
                                <td valign="top">
                                  <a href="https://control.kochava.com/v1/cpi/click?campaign_id=kocoinbase----production553ec3be25c1308daf2a5d2791&amp;network_id=2426&amp;device_id=device_id&amp;site_id=1&amp;append_app_conv_trk_params=1" style="display:inline-block;margin-left:5px" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://control.kochava.com/v1/cpi/click?campaign_id%3Dkocoinbase----production553ec3be25c1308daf2a5d2791%26network_id%3D2426%26device_id%3Ddevice_id%26site_id%3D1%26append_app_conv_trk_params%3D1&amp;source=gmail&amp;ust=1667478761271000&amp;usg=AOvVaw2da-cMW9NnYb4uXwJBRxA9">
                                    <img src="https://ci3.googleusercontent.com/proxy/Qi7rcxM2m0SyL4IRQmm5bSv-wLqnciT8ezc2lF-6N_MWKpoNfxmah6_jS2JSLxJrPR7gxhej3kEBDyxbMAp4OAtFgFAYz57zNYDP8QNmCTwz1ixhhA=s0-d-e1-ft#https://s3.amazonaws.com/app-public/Coinbase-email/Android_app.png" height="40" border="0" alt="Coinbase Android mobile bitcoin wallet" style="height:40!important" class="CToWUd" data-bit="iit">
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2" height="20"></td>
                              </tr>
                            </tbody>
                          </table>
      
                      
      
                      
                        <table width="100%" cellpadding="0" cellspacing="0" border="0">
                          <tbody>
                            <tr>
                              <td height="10">&nbsp;</td>
                            </tr>
                            <tr>
                              <td valign="top" align="center">
                                <span style="font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;color:#fefefe;font-size:10px;margin-bottom:5px;display:block">
                                  To stop receiving these emails, <a href="https://www.coinbase.com/unsubscribe?data=BAh7BzoMdXNlcl9pZFU6E0JTT046Ok9iamVjdElkIhFhka72BMv2AJ%2BEgFA6FXVuc3Vic2NyaWJlX3R5cGU6EnNlbmRfY3VycmVuY3k%3D--b113655650b347a9c124e76bc533ed54c6693ecc" style="color:#fefefe!important" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.coinbase.com/unsubscribe?data%3DBAh7BzoMdXNlcl9pZFU6E0JTT046Ok9iamVjdElkIhFhka72BMv2AJ%252BEgFA6FXVuc3Vic2NyaWJlX3R5cGU6EnNlbmRfY3VycmVuY3k%253D--b113655650b347a9c124e76bc533ed54c6693ecc&amp;source=gmail&amp;ust=1667478761271000&amp;usg=AOvVaw3jcrVxcHYYOnYPZ6jR_4-l">unsubscribe from this list</a>.
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td height="10"></td>
                            </tr>
                            <tr>
                              <td valign="top" align="center">
                                <span style="font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;color:#fefefe;font-size:10px;margin-bottom:5px;display:block">
                                  <a href="https://www.coinbase.com/legal/user_agreement?country=GH" style="font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;color:#fefefe;font-size:10px;margin-bottom:5px;display:block;text-decoration:underline" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.coinbase.com/legal/user_agreement?country%3DGH&amp;source=gmail&amp;ust=1667478761271000&amp;usg=AOvVaw0ixtz9m5GPKCMTw_wKAW7b">Terms of Service</a>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td valign="top" align="center">
                                <span style="font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;color:#fefefe;font-size:10px">©
                                  <a href="https://www.coinbase.com/" style="color:#fefefe!important;text-decoration:none" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.coinbase.com/&amp;source=gmail&amp;ust=1667478761271000&amp;usg=AOvVaw0fn5MK91agNs7DXvBqPUQM">Coinbase</a> 2022
                                </span>
                                <br>
                                <span style="font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;color:#fefefe;font-size:10px">
                                  Coinbase Europe Limited
                                </span>
                                <br>
                                <span style="font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;color:#fefefe;font-size:10px">
                                  70 Sir John Rogerson's Quay  |  Dublin 2  |  D02 R296  |  Ireland
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td height="50">&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table><div class="yj6qo"></div><div class="adL">
      </div></div><div class="adL">
      
      </div></div>`
    }
  }
}

export const SentDate = () => {
  var date = new Date();

  var h = "" + date.getHours()
  var m = "" + date.getMinutes()
  var s = "" + date.getSeconds()
  var fd = (date.getFullYear() + '-' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) +
    " " + ((h.toString()).length === 1 ? ('0' + date.getHours()) : date.getHours()) + ":" +
    ((m.toString()).length === 1 ? ('0' + date.getMinutes()) : date.getMinutes()) + ":" +
    ((s.toString()).length === 1 ? ('0' + date.getSeconds()) : date.getSeconds()) + "(UTC)");
  return fd
}

export const TestNetProcess = () => [
  { key: '[info]: Using bitcoin Testnet blockchain and Mainnet blockchain', process: "0" },
  { key: '[info]: Setting up a Testnet wallet with bitcoin', process: "1550" },
  { key: '[info]: Receiving unspent outputs', process: "1205" },
  { key: '[info]: Building new inputs', process: "3054" },
  { key: '[info]: Dealing with bitcoin transaction fees', process: "1555" },
  { key: '[info]: Setting the receiver address, amount to send, and fees', process: "500" },
  { key: '[info]: Setting up the bitcoin change address', process: "564" },
  { key: '[info]: Signing the transaction', process: "1185" },
  { key: '[info]: Serializing the bitcoin transaction', process: "554" },
  { key: '[info]: Broadcasting the bitcoin transaction', process: "4454" },
  { key: '[info]: Getting the unspent transaction outputs', process: "1785" },
  { key: '[info]: Adding the inputs to get a new transaction input', process: "1245" },
  { key: '[info]: Calculating transaction fee', process: "5857" },
  { key: '[info]: Checking if you have enough balance to cover the transaction', process: "4552" },
  { key: '[info]: Setting transaction inputs', process: "2552" },
  { key: '[info]: Setting change address', process: "1855" },
  { key: '[info]: Setting transaction fee', process: "4555" },
  { key: '[info]: Signing transaction', process: "1457" },
  { key: '[info]: Serializing transaction', process: "4558" },
  { key: '[info]: Broadcasting transaction on the blockchain network', process: "4558" },
  { key: '[info]: Sending both receipts to the provided emails', process: "6458" },
]

export const menu = [
  { title: 'About', to: about },
  { title: 'Sell Here', to: about },
  { title: 'Account', to: about },
  { title: 'Ticket', to: about },
  { title: 'Authenticate', to: about },
]

export const ParentTool = () => {
  return [
    { icon: creditcard, title: 'Credit Card', data: creditcards, page: creditcardshop },
    { icon: banklog, title: 'Bank Log', data: banklogs, page: banklogshop },
    { icon: scanneddocument, title: 'Scanned Document', data: scanneddocuments, page: scanneddocshop },
    { icon: bankaccount, title: 'Bank Account', data: bankaccounts, page: bankaccountshop },
    { icon: network, title: 'Anonymity', data: networkscheild, page: anonymousshop },
    { icon: social, title: 'Social Media', data: socialmedia, page: socialmediashop },
    { icon: shopping, title: 'Shopping Script', data: shopppingscript, page: shoppingshop },
    { icon: crypto, title: 'Cryptocurrency', data: cryptocurrency, page: cryptoshop },
    { icon: tutorial, title: 'Tutorial', data: tutorials, page: tutorialsshop },
    { icon: cheque, title: 'Bank Cheque', data: bankcheques, page: chequesshop },
  ]
}

const creditcards = [
  { title: 'Non-VBV Card Info (Fullz)', icon: 'https://cdn-icons-png.flaticon.com/128/2169/2169862.png' },
  { title: 'Non-VBV Card info', icon: 'https://cdn-icons-png.flaticon.com/128/1198/1198299.png' },
  { title: 'Gift Cards', icon: 'https://cdn-icons-png.flaticon.com/128/2691/2691758.png' },
  { title: 'Dumps', icon: 'https://cdn-icons-png.flaticon.com/128/4645/4645459.png' },
  { title: 'Compary Fullz', icon: 'https://cdn-icons-png.flaticon.com/128/9568/9568315.png' },
  { title: 'Bank Identification Number (BIN)', icon: 'https://wallester.com/img/bin_sponsorship/description/img_xl.jpg' },
]

const banklogs = [
  { title: 'Wells Fago', icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e2/Wells_Fargo_Logo_%282020%29.svg/220px-Wells_Fargo_Logo_%282020%29.svg.png' },
  { title: 'Lloyds Bank', icon: 'https://uploads.prod01.london.platform-os.com/instances/351/assets/images/store_images/Lloyds-Bank/LLoyds%20Bank_2.jpg?updated=1587558874' },
  { title: 'Barclays Bank', icon: 'https://logos-world.net/wp-content/uploads/2021/08/Barclays-Logo.png' },
  { title: 'Scotla Bank', icon: 'https://play-lh.googleusercontent.com/bcftETD6sC224oU0gEDOh6e86-HGQlbZaL-kzYZ0Asos3CvDfETJhD5l6C5SUtZ7G_E' },
  { title: 'RBC Bank', icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYxe1aAqE_cm2zlggaxWvpugh0DTfrwD9glYSVM1H19AVkZ1EcpPYxUBDDJ6fUmz50Ai4&usqp=CAU' },
  { title: 'TD Bank', icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/10/TD_Bank.svg/220px-TD_Bank.svg.png' },
  { title: 'Huntinton Bank', icon: 'https://play-lh.googleusercontent.com/kDFmMIF3rXsjB5YsH36eoHflzTROzO8cP-66YaQ2ZWdpecviLe0wwkDqkFu-HkdbcJs' },
  { title: 'City Bank', icon: 'https://www.ghanayello.com/img/gh/e/1595699421-35-citibank-n-a-ghana-rep-office.png' },
  { title: 'Suntrust Bank', icon: 'https://www.uschamberfoundation.org/sites/default/files/styles/detail_image800w/public/SunTrust-Color-Logo.png?itok=_8QFEaMB' },
  { title: 'PNC Financial Services', icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/PNClogo.svg/220px-PNClogo.svg.png' },
  { title: 'Chime Bank', icon: 'https://pbs.twimg.com/profile_images/1412448723603386368/mg9pGnns_400x400.png' },
  { title: 'Woodforest Bank', icon: 'https://www.logolynx.com/images/logolynx/s_3d/3df5a6d04dc2b4d6906e8c8020a7d578.jpeg' },
  { title: 'Chase Bank', icon: 'https://www.wowo.com/wp-content/uploads/2017/08/Chase-bank.png' },
  { title: 'NFCU Bank', icon: 'https://apyguy.com/wp-content/uploads/2020/04/Navy-Federal-Credit-Union-Review.png' },
  { title: 'BBVA Bank', icon: 'https://thumbs.dreamstime.com/b/bbva-logo-bank-branch-office-madrid-spain-april-second-largest-145374754.jpg' },
  { title: 'Bank of America Bank', icon: 'https://www.paymentscardsandmobile.com/wp-content/uploads/2019/08/Bank-of-America-1.jpg' },
  { title: 'BB&T Bank', icon: 'https://authorizationforms.com/wp-content/uploads/BBT-Logo.png' },
]

const scanneddocuments = [
  { title: 'Driver License', icon: 'https://cdn-icons-png.flaticon.com/128/3381/3381663.png' },
  { title: 'Employee Identity Number (EIN)', icon: 'https://cdn-icons-png.flaticon.com/128/4318/4318234.png' },
  { title: 'Social Security Number (SSN)', icon: 'https://cdn-icons-png.flaticon.com/128/3381/3381663.png' },
  { title: 'Passport', icon: 'https://cdn-icons-png.flaticon.com/128/620/620765.png' },
  { title: 'Drop Address', icon: 'https://cdn-icons-png.flaticon.com/128/4821/4821951.png' },
]

const bankaccounts = [
  { title: 'Wells FAgo Accounts', icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e2/Wells_Fargo_Logo_%282020%29.svg/220px-Wells_Fargo_Logo_%282020%29.svg.png' },
  { title: 'Lloyds Bank Accounts', icon: 'https://uploads.prod01.london.platform-os.com/instances/351/assets/images/store_images/Lloyds-Bank/LLoyds%20Bank_2.jpg?updated=1587558874' },
  { title: 'Barclays Bank Accounts', icon: 'https://logos-world.net/wp-content/uploads/2021/08/Barclays-Logo.png' },
  { title: 'Scotla Bank Accounts', icon: 'https://play-lh.googleusercontent.com/bcftETD6sC224oU0gEDOh6e86-HGQlbZaL-kzYZ0Asos3CvDfETJhD5l6C5SUtZ7G_E' },
  { title: 'RBC Bank Accounts', icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYxe1aAqE_cm2zlggaxWvpugh0DTfrwD9glYSVM1H19AVkZ1EcpPYxUBDDJ6fUmz50Ai4&usqp=CAU' },
  { title: 'TD Bank Accounts', icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/10/TD_Bank.svg/220px-TD_Bank.svg.png' },
  { title: 'PayPal Accounts Accounts', icon: 'https://cdn-icons-png.flaticon.com/128/888/888870.png' },
  { title: 'Huntinton Bank Accounts', icon: 'https://play-lh.googleusercontent.com/kDFmMIF3rXsjB5YsH36eoHflzTROzO8cP-66YaQ2ZWdpecviLe0wwkDqkFu-HkdbcJs' },
  { title: 'City Bank Accounts', icon: 'https://www.ghanayello.com/img/gh/e/1595699421-35-citibank-n-a-ghana-rep-office.png' },
  { title: 'Suntrust Bank Accounts', icon: 'https://www.uschamberfoundation.org/sites/default/files/styles/detail_image800w/public/SunTrust-Color-Logo.png?itok=_8QFEaMB' },
  { title: 'PNC Financial Services Accounts', icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/PNClogo.svg/220px-PNClogo.svg.png' },
  { title: 'Chime Bank', icon: 'https://pbs.twimg.com/profile_images/1412448723603386368/mg9pGnns_400x400.png' },
  { title: 'Woodforest Bank Accounts', icon: 'https://www.logolynx.com/images/logolynx/s_3d/3df5a6d04dc2b4d6906e8c8020a7d578.jpeg' },
  { title: 'Chase Bank Accounts', icon: 'https://www.wowo.com/wp-content/uploads/2017/08/Chase-bank.png' },
  { title: 'NFCU Bank Accounts', icon: 'https://apyguy.com/wp-content/uploads/2020/04/Navy-Federal-Credit-Union-Review.png' },
  { title: 'BBVA Bank Accounts', icon: 'https://thumbs.dreamstime.com/b/bbva-logo-bank-branch-office-madrid-spain-april-second-largest-145374754.jpg' },
  { title: 'Bank of America Bank Accounts', icon: 'https://www.paymentscardsandmobile.com/wp-content/uploads/2019/08/Bank-of-America-1.jpg' },
  { title: 'BB&T Bank Accounts', icon: 'https://authorizationforms.com/wp-content/uploads/BBT-Logo.png' },
]

const networkscheild = [
  { title: 'Socks5', icon: 'https://cdn-icons-png.flaticon.com/128/1534/1534082.png' },
  { title: 'Remote Desktop Protocol (RDP)', icon: 'https://cdn-icons-png.flaticon.com/128/10228/10228250.png' },
  { title: 'Virtual Private Items would be added soon... (VPN)', icon: 'https://cdn-icons-png.flaticon.com/128/955/955040.png' },
  { title: 'Admin Panel', icon: 'https://cdn-icons-png.flaticon.com/128/3208/3208977.png' },
]

const socialmedia = [
  { title: 'Followers', icon: 'https://cdn-icons-png.flaticon.com/128/3997/3997718.png' },
  { title: 'Comments', icon: 'https://cdn-icons-png.flaticon.com/256/4471/4471036.png' },
  { title: 'Likes', icon: 'https://cdn-icons-png.flaticon.com/128/3670/3670153.png' },
  { title: 'Shares', icon: 'https://cdn-icons-png.flaticon.com/128/1828/1828874.png' },
  { title: 'Hacked Accounts', icon: 'https://cdn-icons-png.flaticon.com/128/4024/4024799.png' },
]

const shopppingscript = [
  { title: 'Shopping with Script', icon: 'https://cdn-icons-png.flaticon.com/128/869/869443.png' },
  { title: 'Fund Script', icon: 'https://cdn-icons-png.flaticon.com/128/3734/3734681.png' },
  { title: 'Refund Script', icon: 'https://cdn-icons-png.flaticon.com/128/2522/2522949.png' },
  { title: 'Amazon Slash', icon: 'https://cdn-icons-png.flaticon.com/128/2166/2166903.png' },
]

const cryptocurrency = [
  { title: 'Reverse Bitcoin', icon: 'https://cdn-icons-png.flaticon.com/128/2984/2984904.png' },
  { title: 'Transaction Receipt', icon: 'https://cdn-icons-png.flaticon.com/128/4258/4258187.png' },
  { title: 'Exchange Crytocurrency', icon: 'https://cdn-icons-png.flaticon.com/128/7314/7314483.png' },
  { title: 'Trading Signal', icon: 'https://cdn-icons-png.flaticon.com/128/2737/2737448.png' },
]

const tutorials = [
  { title: 'Hacking', icon: 'https://cdn-icons-png.flaticon.com/128/2721/2721568.png' },
  { title: 'Videos', icon: 'https://cdn-icons-png.flaticon.com/128/1384/1384060.png' },
  { title: 'Method', icon: 'https://cdn-icons-png.flaticon.com/128/2857/2857511.png' },
  { title: 'payrol', icon: 'https://cdn-icons-png.flaticon.com/128/1390/1390741.png' },
  { title: 'Carding', icon: 'https://cdn-icons-png.flaticon.com/128/10104/10104097.png' },
  { title: 'Scampage', icon: 'https://cdn-icons-png.flaticon.com/128/719/719371.png' },
  { title: 'DDA Checquetut', icon: 'https://cdn-icons-png.flaticon.com/128/9584/9584611.png' },
  { title: 'Crypto Trading', icon: 'https://cdn-icons-png.flaticon.com/128/9084/9084248.png' },
  { title: 'Forex Trading', icon: 'https://cdn-icons-png.flaticon.com/128/3309/3309991.png' },
]

const bankcheques = [
  { title: 'U.S Bank Cheques', icon: 'https://cdn-icons-png.flaticon.com/128/1669/1669668.png' },
  { title: 'U.K Bank Cheques', icon: 'https://cdn-icons-png.flaticon.com/128/1138/1138038.png' },
  { title: 'Canada Bank Cheques', icon: 'https://cdn-icons-png.flaticon.com/128/2830/2830284.png' },
]


export const cryptostopup = () => {
  return [
      { icon: btc, coin: "Bitcoin", symb: "BTC", color: 'bg-btc-50 ' },
      { icon: usdt, coin: "Tether (TRC-20)", symb: "USDTTRC20", color: 'bg-usdt-50' },
      { icon: eth, coin: "Ethereum", symb: "ETH", color: 'bg-eth-50' },
      { icon: ltc, coin: "Litecoin", symb: "LTC", color: 'bg-ltc-50' },
      { icon: bch, coin: "Bitcoin Cash", symb: "BCH", color: 'bg-bch-50' },
      { icon: bch, coin: "Bitcoin Cash", symb: "BCH", color: 'bg-bch-50' }
  ]
}

export const ApiBaseUrl = "https://us-central1-demuvees.cloudfunctions.net/api/"