import React from "react";
import ScannedDocumentsComponent from "../components/scanneddoc";
import { Helmet } from "react-helmet";
const ScannedDocumentPage = () => {
  return (
    <div className="w-full  md:w-5/6 lg:w-5/6">
      <Helmet>
        <meta charset="utf-8" />
        
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="This page offers digital documents for your online activities, hacking tools; Passport, Visa, Driver's license, ssn, ein, social security number, employee identification number, spamming, bank accounts, vpn, socks5, etc, rdp"
        />
        <meta
          name="keyword"
          content="ssn, ein, passport, driver's lincese, visa, cryptocurrency, bank logs, bank cheque, carding, spamming, bank, account, hacking, vpn, socks5, rdp"
        />
        <link rel="canonical" href="https://uniccs.cc/useraccountsanddocumentts" />
        <titie>
          Scanned Documents - Passport, Visa, Driver's license, SSN, EIN
        </titie>
      </Helmet>
      <div className="mt-28">
        <ScannedDocumentsComponent />
      </div>
    </div>
  );
};

export default ScannedDocumentPage;
