import React, { useLayoutEffect, useState } from "react";
import { MdMenu } from "react-icons/md";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  about,
  account,
  sell,
  ticket,
  auth,
  mainhome,
  althome,
  userscollection,
} from "../config/data";
import { GetADoc, Signout } from "../config/db";
import { Confirm, InitUser, UserExist } from "../config/functions";
import { Paymentinitdialog } from "../payments/initpayments";
import { useRef } from "react";

const Mobilenav = () => {
  const [isUser, setIsUser] = useState(false);
  const [balance, setBalance] = useState(0);
  const user = InitUser();
  const navigation = useNavigate();
  const progressref = useRef();
  useLayoutEffect(() => {
    if (UserExist.exist === true) {
      setBalance(user.balance);
      setIsUser(true);
    } else {
      setIsUser(false);
    }
  }, [user]);
  return (
    <ErrorBoundary>
      <div className="fixed top-0 right-0 left-0 z-10 flex lg:hidden py-5 w-full items-center bg-black">
        <MdMenu
          className="ml-5 md:ml-20 z-10 text-white hover:cursor-pointer transition duration-150 ease-in-out"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
          size={35}
        />
        <div className="flex w-full justify-center">
          <NavLink className="nav-link" to={mainhome}>
            <h1 className="text-red-400 text-5xl font-bold text-center ">
              Uniccs<span className="text-white font-thin">Tools</span>
            </h1>
          </NavLink>
        </div>
        <div
          className="offcanvas offcanvas-start fixed bottom-0 flex flex-col max-w-full bg-secondary-dark-bg invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 left-0 border-none w-96 cursor-default select-none"
          tabIndex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header flex items-center justify-between p-4 ">
            <li className="navbar-nav  text-off-white flex flex-row gap-4">
              {" "}
              <NavLink className="nav-link" to={althome}>
                <h1 className="text-red-400 text-5xl font-bold text-center ">
                  Uniccs<span className="text-white font-thin">Tools</span>
                </h1>
              </NavLink>{" "}
            </li>
            <button
              type="button"
              className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-red-700 text-lg border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>

          <div className="offcanvas-body flex-grow p-4 overflow-y-auto flex flex-col mb-10">
            <ul className="flex flex-col gap-4 text-white font-medium">
              <li className="navbar-nav  text-off-white flex flex-row gap-4">
                <NavLink className="nav-link" to={about}>
                  About uniccs tools
                </NavLink>
              </li>
              {isUser && (
                <li className="navbar-nav  text-off-white flex flex-row gap-4">
                  <NavLink
                    className="nav-link"
                    style={({ isActive }) => ({
                      fontWeight: isActive ? "900" : "white",
                      color: isActive ? "#6D6D6D" : "#ffffff",
                    })}
                    to={account}
                  >
                    Account
                  </NavLink>
                </li>
              )}
              {/* {isUser && (
                <li className="navbar-nav  text-off-white flex flex-row gap-4">
                  <NavLink className="nav-link" to={sell}>
                    Sell Your Staffs
                  </NavLink>
                </li>
              )} */}
              {!isUser && (
                <li className="navbar-nav  text-off-white flex flex-row gap-4">
                  <NavLink className="nav-link" to={auth}>
                    Authenticate Account
                  </NavLink>
                </li>
              )}
              {isUser && (
                <li
                  className="navbar-nav  text-off-white flex flex-row gap-4 hover:cursor-pointer"
                  onClick={() =>
                    Paymentinitdialog({
                      amt: null,
                      bal: balance,
                      nav: navigation,
                      ref: progressref,
                      title: "Topup Account Balance",
                      body: null,
                    })
                  }
                >
                  Balance: ${balance.toLocaleString()}
                </li>
              )}
              {isUser && (
                <li
                  className="navbar-nav text-red-600 flex flex-row gap-4 hover:cursor-pointer"
                  onClick={() => {
                    Confirm({
                      title: "Singout",
                      msg: "Are you sure you want to sign out of this account?",
                      action: () => Signout(),
                    });
                  }}
                >
                  SIGNOUT
                </li>
              )}
            </ul>
          </div>
        </div>
        {/* <div
        className="invisible fixed bottom-0 left-0 top-0 z-[1045] flex w-96 max-w-full -translate-x-full flex-col border-none bg-white bg-clip-padding text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out dark:bg-neutral-800 dark:text-neutral-200 [&[data-te-offcanvas-show]]:transform-none"
        type="button"
        id="dropdownMenuButton"
        data-te-dropdown-toggle-ref
        data-te-ripple-init
        data-te-ripple-color="light"
      >
        <div className="flex-grow overflow-y-auto p-4">
          <ul className="flex flex-col gap-4 text-white font-medium">
            <li>About </li>
            <li>Sell your tools</li>
            <li>Ticket</li>
            <li>Account</li>
            <li>Sign in</li>
          </ul>
        </div>
      </div> */}
      </div>
    </ErrorBoundary>
  );
};

export default Mobilenav;
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
