import React from "react";
import BankChequeComponent from "../components/bankchequecomponent";
import { Helmet } from "react-helmet";
const BankChequePage = () => {
  return (
    <div className="w-full  md:w-5/6 lg:w-5/6">
      <Helmet>
        <meta charset="utf-8" />
        
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Wells Fago, Lloyds Bank, Barclays Bank, Scotla Bank, RBC Bank, TD Bank, Huntinton Bank, City Bank, Suntrust Bank, PNC Financial Services, Chime Bank, Woodforest Bank, Chase Bank, NFCU Bank, BBVA Bank, Bank of America Bank, BB&T Bank bank logs, bank cheque, carding, spamming, bank accounts, vpn, socks5, etc, rdp"
        />
        <meta
          name="keyword"
          content="ells Fago, Lloyds Bank, Barclays Bank, Scotla Bank, RBC Bank, TD Bank, Huntinton Bank, City Bank, Suntrust Bank, PNC Financial Services"
        />
        <link rel="canonical" href="https://uniccs.cc/bankchequesdesignandcashout" />
        <titie>
          Bank Checks Page - Banklogs, bank, checks, chequs
        </titie>
      </Helmet>
      <div className="mt-28">
        <BankChequeComponent />
      </div>
    </div>
  );
};

export default BankChequePage;
