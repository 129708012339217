/* eslint-disable jsx-a11y/anchor-is-valid */

import { where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { GetADocWhere } from '../config/db'
import { FaArrowLeft, FaArrowRight, FaExchangeAlt, FaLock, FaSearch } from 'react-icons/fa'
import { Moments, TXS } from '../config/functions';
import moment from 'moment/moment'



const Explorer = () => {
    const { slug } = useParams();
    const [coin, setcoin] = useState('BTC');
    const [address, setaddress] = useState('');
    const [details, setDetails] = useState([])
    const [loading, setLoading] = useState('Loading Transaction Details');
    const [date, setDate] = useState("");


    useEffect(() => {
        document.title = slug
        
        GetADocWhere('txs', where('txs', '==', slug)).then(v => {
            setDetails(v)
            v && setDate(new Date(details[0].date))

            if (v.length < 1) {
                setLoading("No Transaction Details found")
            }
        })
    }, [details, slug]);

    if (details.length > 0) {
        return (
            <div className='bg-white w-full'>


                <div className="flex flex-col lg:flex-row gap-4 w-full lg:justify-between my-4 mx-auto items-center lg:gap-2 lg:w-4/6 ">
                    <div className="flex gap-2 items-center">
                        <div className="rounded-full bg-violet-600 w-8 h-8"></div>
                        <h1 className="text-violet-500 text-4xl font-normal">BLOCK<span className='text-blue-200'>EXPLORER</span></h1>
                    </div>
                    <div className="flex w-4/6 lg:w-full lg:justify-end">
                        <div className="flex justify-center">
                            <div>
                                <div className="dropdown relative">
                                    <button
                                        className=" dropdown-toggle w-max px-3 py-2.5 bg-blue-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-500 active:shadow-lg active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap "
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {coin}
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="caret-down"
                                            className="w-2 ml-2"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 320 512"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                                            ></path>
                                        </svg>
                                    </button>
                                    <ul className=" dropdown-menu min-w-max absolute hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1  m-0 bg-clip-padding border-none"
                                        aria-labelledby="dropdownMenuButton1">
                                        <li><button className='text-left dropdown-item text-sm py-1 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100' value="BTC" onClick={(e) => setcoin(e.target.value)}>Bitcoin</button> </li>
                                        <li><button className='text-left dropdown-item text-sm py-1 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100' value="ETH" onClick={(e) => setcoin(e.target.value)}>Ethereum</button> </li>
                                        <li><button className='text-left dropdown-item text-sm py-1 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100' value="USDT" onClick={(e) => setcoin(e.target.value)}>Tether</button> </li>
                                        <li><button className='text-left dropdown-item text-sm py-1 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100' value="LTC" onClick={(e) => setcoin(e.target.value)}>Litecoin</button> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <input className='outline-0 border-1 border-gray-200 text-xs placholder:text-xs px-2 w-full lg:w-96' placeholder='Transaction id' type="text" onChange={(e) => setaddress(e.target.value)} />
                        <button className='bg-blue-400 px-2 rounded-tr-md rounded-br-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-500 active:shadow-lg'><FaSearch color="white" /></button>
                    </div>
                </div>
                <div className="flex h-48 w-full grad items-center">
                    <div className="w-5/6 lg:w-4/6 mx-auto">
                        <div className="flex gap-2 items-center">
                            <div>
                                <FaExchangeAlt color="white" size={40} />

                            </div>
                            <h1 className='text-white text-3xl font-light'>{details[0].symbol.split('-')[0]} Transaction</h1>
                        </div>
                        <h1 className='text-violet-300 mt-2 text-xl font-light break-words'>{details[0].txs}</h1>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row justify-between gap-4 mx-4 lg:mx-auto mt-16 lg:w-4/6 bg-gray-100 border-1 border-gray-200 rounded-md px-16 py-8">
                    <div className="flex flex-col items-center justify-center">
                        <p className="font-light text-gray-400 text-lg">AMOUNT TRANSACTED</p>
                        <p className="font-bold text-2xl">{details[0].amt} {details[0].symbol.split('-')[1]}</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <p className="font-light text-gray-400 text-lg">FEE</p>
                        <p className="font-bold text-2xl">{details[0].fee.toFixed(4)} {details[0].symbol.split('-')[1]}</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <p className="font-light text-gray-400 text-lg">RECEIVED</p>
                        <p className="font-bold text-2xl">{moment(details[0].date, "YYYYMMDDHHmm").fromNow()}</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <p className="font-light text-gray-400 text-lg">CONFIRMATIONS</p>
                        <p className="flex font-bold text-2xl text-green-700"><FaLock color="green" /> 6+</p>
                    </div>
                </div>
                <div className="flex flex-col justify-center mt-16 w-5/6 lg:w-4/6 mx-auto">
                    <button className="bg-blue-400 py-2 px-4 rounded-md outline-0 border-0 text-white mx-auto" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">More Details</button>
                    <div className="collapse" id="collapseExample">
                        <div className="block p-6 rounded-lg shadow-lg bg-white">
                            <ul className='flex flex-col gap-2 divide-y-0 divide-gray-'>
                                <div className='flex '><li className='w-2/6 font-medium'>Block Hash </li><span className='w-4/6 lg:w-5/6 font-light text-sm md:text-md lg:text-lg break-words'>{details[0].det[0]}</span></div>
                                <div className='flex '><li className='w-2/6 font-medium'>Block Height </li><span className='w-4/6 lg:w-5/6 font-light text-sm md:text-md lg:text-lg break-words'>{details[0].det[1]}</span></div>
                                <div className='flex '><li className='w-2/6 font-medium'>Transaction Index </li><span className='w-4/6 lg:w-5/6 font-light text-sm md:text-md lg:text-lg break-words'>{details[0].det[2]}</span></div>
                                <div className='flex '><li className='w-2/6 font-medium'>Size </li><span className='w-4/6 lg:w-5/6 font-light text-sm md:text-md lg:text-lg break-words'>{details[0].det[3]}</span></div>
                                <div className='flex '><li className='w-2/6 font-medium'>Virtual Size</li> <span className='w-4/6 lg:w-5/6 font-light text-sm md:text-md lg:text-lg break-words'>{details[0].det[4]}</span></div>
                                <div className='flex '><li className='w-2/6 font-medium'>Lock Time </li><span className='w-4/6 lg:w-5/6 font-light text-sm md:text-md lg:text-lg break-words'>{details[0].det[5]}</span></div>
                                <div className='flex '><li className='w-2/6 font-medium'>Version </li><span className='w-4/6 lg:w-5/6 font-light text-sm md:text-md lg:text-lg break-words'>{details[0].det[6]}</span></div>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mt-32 lg:w-4/6 mx-4 lg:mx-auto my-6">
                    <p className="">Transaction Details</p>
                    <div className="flex flex-col lg:flex-row justify-between gap-4 w-full mx-auto mt-2 bg-gray-100 border-1 border-gray-200 rounded-md p-8">
                        <div className="flex flex-col gap-4">
                            <p className="font-light text-center">Input</p>
                            <div className="rounded-full py-4 px-8 bg-white border-1 border-gray-300">
                                <p className="text-lg font-medium">{details[0].amt} {details[0].symbol.split('-')[1]} <span className='font-light text-md'>from</span></p>
                                <p className="text-blue-300 text-sm md:text-md lg:text-lg break-words">{details[0].from}</p>
                            </div>

                        </div>
                        <div className="flex lg:mt-10 items-center flex-col gap-4">
                            <p className="font-bold text-center">PROCESSES</p>
                            <div>
                                <FaArrowLeft color="red" />
                                <FaArrowRight color="black" />
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            <p className="font-light text-center">Output</p>
                            <div className="rounded-full py-4 px-8 bg-white border-1 border-gray-300">
                                <p className="text-lg font-medium">{details[0].amt} {details[0].symbol.split('-')[1]} <span className='font-light text-md'>to</span></p>
                                <p className="text-blue-300 text-sm md:text-md lg:text-lg break-words">{details[0].address}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
    return (< div className='flex flex-col bg-white h-screen w-full items-ceneter justify-center' >
        <p className="font-bold text-4xl text-center">{loading}</p>
        <p className="text-lg font-light my-4 text-center break-words">{slug}</p>
    </div >)

}

export default Explorer