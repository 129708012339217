import React from "react";
import AnonymityComponent from "../components/anonymitycomponent";
import { Helmet } from "react-helmet";
const AnonymityPage = () => {
  return (
    <div className="w-full md:w-5/6 lg:w-5/6">
      <Helmet>
        <meta charset="utf-8" />
        
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Protect yourself on the internet from prying eyes, ISP, inter organisations, hacker, middle men, spammers, etc with tons of vpn, socks5, etc, rdp and any online security tool or material."
        />
        <meta
          name="keyword"
          content="cryptocurrency, bank logs, bank cheque, carding, spamming, bank account hacking, vpn, socks5, rdp"
        />
        <link rel="canonical" href="https://uniccs.cc/vpnsocks5rdpadminpannel" />
        <titie>
          Anonimity - VPN, SOCKS5, RDP, Remote Desktop Protocol, VPN, Viertual
          Private Network, Admin Panel
        </titie>
      </Helmet>
      <div className="mt-28">
        <AnonymityComponent />
      </div>
    </div>
  );
};

export default AnonymityPage;
