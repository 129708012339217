import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  about,
  account,
  auth,
  mainhome,
  menu,
  sell,
  userscollection,
} from "../config/data";
import { GetADoc, Signout } from "../config/db";
import { Confirm, InitUser, UserExist } from "../config/functions";
import { Paymentinitdialog } from "../payments/initpayments";
import { useRef } from "react";

const Navbar = ({ title, cat }) => {
  const [isUser, setIsUser] = useState(false);
  const [balance, setBalance] = useState(0);
  const user = InitUser();
  const progressref = useRef(null);
  const navigation = useNavigate();

  useEffect(() => {
    if (UserExist.exist === true) {
      setBalance(user.balance);
      setIsUser(true);
    } else {
      setIsUser(false);
    }
  }, [isUser, user.balance]);

  return (
    <div className="fixed top-0 right-0  py-4 left-0 w-full bg-black bg-opacity-60 z-10 flex justify-center">
      <div className="hidden lg:flex justify-between mx-auto items-center bg-black bg-opacity-60 w-5/6">
        <NavLink className="nav-link" to={mainhome}>
          <h1 className="text-red-400 text-5xl font-bold text-center ">
            Uniccs<span className="text-white font-thin">Tools</span>
          </h1>
        </NavLink>
        {/*  <h1 className="font-bold text-lg text-white my-8">{cat}</h1> */}
        <ul className="flex gap-4 text-white font-medium text-sm items-center">
          <li className="navbar-nav  text-off-white flex flex-row gap-4">
            <NavLink
              className="nav-link"
              style={({ isActive }) => ({
                fontWeight: isActive ? "900" : "white",
                color: isActive ? "#6D6D6D" : "#ffffff",
              })}
              to={about}
            >
              About
            </NavLink>
          </li>

          {isUser && (
            <li className="navbar-nav  text-off-white flex flex-row gap-4">
              <NavLink
                className="nav-link"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "900" : "white",
                  color: isActive ? "#6D6D6D" : "#ffffff",
                })}
                to={account}
              >
                Account
              </NavLink>
            </li>
          )}
          {!isUser && (
            <li className="navbar-nav  text-off-white flex flex-row gap-4">
              <NavLink
                className="nav-link"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "900" : "white",
                  color: isActive ? "#6D6D6D" : "#ffffff",
                })}
                to={auth}
              >
                Authenticate Account
              </NavLink>
            </li>
          )}
          {isUser && (
            <li
              className="navbar-nav text-red-600 flex flex-row gap-4 hover:cursor-pointer"
              onClick={() => {
                Confirm({
                  title: "Singout",
                  msg: "Are you sure you want to sign out of this account?",
                  action: () => Signout(),
                });
              }}
            >
              SIGNOUT
            </li>
          )}
          {isUser && (
            <div
              className="rounded-lg py-2 px-4 bg-red-600 font-bold hover: cursor-pointer"
              onClick={() =>
                Paymentinitdialog({
                  amt: null,
                  bal: balance,
                  nav: navigation,
                  ref: progressref,
                  title: "Topup Account Balance",
                  body: null,
                })
              }
            >
              <li className="navbar-nav  text-off-white flex flex-row gap-4">
                Balance: ${balance.toLocaleString()}
              </li>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
