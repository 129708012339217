import React from "react";
import CryptocurrencyCompnent from "../components/cryptocurrencycomponent";
import { Helmet } from "react-helmet";
const CryptocurrencyPage = () => {
  return (
    <div className="w-full  md:w-5/6 lg:w-5/6">
      <Helmet>
        <meta charset="utf-8" />
        
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Buy sell your cryptocurrency with us, we also offer tools which reverses cryptocurrency transaction with no moments, transact cryptocurrency with shopping script, bank logs, bank account, bank cheque, carding, spamming, refund scirpt, fundscript, discount amazon slash"
        />
        <meta
          name="keyword"
          content="forex, crypto, bitcoin, usdt, tether, ethereum, eth, futures trading, day trading day scalping"
        />
        <link rel="canonical" href="https://uniccs.cc/shoppingscripts" />
        <titie>
          Cryptocurrency Page - Amazon discount, amazon products, shop with
          script
        </titie>
      </Helmet>
      <div className="mt-28">
        <CryptocurrencyCompnent />
      </div>
    </div>
  );
};

export default CryptocurrencyPage;
