import React from "react";

const Progressdialog = ({ isProgress }) => {
  return (
    <div className="">
      {isProgress && (
        <div className="fixed top-0 right-0 bottom-0 left-0 bg-opacity-50">
          <div className="loader-wrapper">
            <div className="loader"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Progressdialog;
