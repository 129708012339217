import React, { useState, useEffect } from "react";
import Mobilenav from "./mobilenav";
import Navbar from "./navbar";
import {
  GetADoc,
  GetADocWhere,
  SetADoc,
  UID,
  UpdateADoc,
  auth,
} from "../config/db";
import {
  userscollection,
  purchasecollection,
  uniccstoolsreports,
} from "../config/data";
import { Info, InitUser } from "../config/functions";
import { FaDownload, FaEdit, FaEye, FaTelegram } from "react-icons/fa";
import { Paymentinitdialog } from "../payments/initpayments";
import { useNavigate } from "react-router-dom";
import Progressdialog from "./progres";
import { updatePassword } from "firebase/auth";
import { SimpleShareButtons } from "react-simple-share";
import { where } from "firebase/firestore";
import moment from "moment/moment";
const AccountComponent = () => {
  const accountmenu = ["Account info", "Purchases", "HelpDesk", "Sales"];
  const [currentItem, setCurrentItem] = useState(0);
  const [user, setUser] = useState([]);

  useEffect(() => {
    GetADoc(userscollection, InitUser().uid).then((value) => {
      setUser([value.data()]);
      localStorage.setItem("user", JSON.stringify([value.data()]));
      InitUser();
    });
    InitUser();
  }, []);
  return (
    <div className="flex flex-col justify-center text-gray-300 bg-black bg-opacity-60 px-4 md:px-2 lg:px-0">
      <Navbar title={""} cat={""} />
      <Mobilenav />
      <div className="bg-black bg-opacity-50">
        <div className="bg-neutral-500 flex border-1 border-neutral-500 w-max">
          {accountmenu.map((value, index) => (
            <button
              className={`transition-all delay-75 btn px-4 py-2 ${
                currentItem === index ? "text-red-500" : "text-white"
              } font-bold text-md ${
                currentItem === index ? "bg-neutral-400" : "bg-neutral-500"
              }`}
              key={value}
              onClick={() => setCurrentItem(index)}
            >
              {value}
            </button>
          ))}
        </div>
        {currentItem === 0 && <AccountInfo />}
        {currentItem === 1 && <Purchases />}
        {currentItem === 2 && <Helpdesk />}
        {currentItem === 3 && <Sales />}
      </div>
    </div>
  );
};

export default AccountComponent;

const AccountInfo = () => {
  const navigation = useNavigate();
  const [username, setUsername] = useState(InitUser().username);
  const [password, setPassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [progress, setProgress] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      reset(setError);
      if (username === InitUser().username && password === "") {
        setError("Cannot update the same username");
        return;
      }
      if (username === "") {
        setError("Enter your username");
        return;
      }
      if (password !== "" && password !== InitUser().password) {
        setError("Your old password is incorrect");
        return;
      }
      if (password !== "" && newpassword === "") {
        setError("New password is required");
        return;
      }

      setProgress(true);
      UpdateADoc(userscollection, InitUser().uid, { username })
        .then((v) => {
          if (password !== "" && newpassword !== "") {
            const user = auth.currentUser;
            updatePassword(user, newpassword)
              .then(async () => {
                await UpdateADoc(userscollection, InitUser().uid, {
                  password: newpassword,
                });
                setProgress(false);
                window.location.reload();
              })
              .catch((error) => {
                setProgress(false);
                console.log(error);
              });
            return;
          }

          setProgress(false);
          window.location.reload();
        })
        .catch((err) => {
          setProgress(false);
        });
    } catch (error) {
      console.error(JSON.stringify(error));
      setProgress(false);
      setError(error.code);
    }
  };

  const reset = (setError) => {
    setTimeout(() => {
      setError("");
    }, 5000);
  };
  const OptionsExample = () => (
    <div className="flex">
      <SimpleShareButtons
        url="https://uniccs.cc/"
        //color="#03A9F4"
        size="40px"
      />
    </div>
  );
  return (
    <div className="flex flex-col lg:flex-row w-full my-10 ">
      <div className="flex flex-col p-4 gap-8 w-full ">
        <div className="flex gap-2 items-center">
          <p className="text-neutral-500 text-lg">username: </p>
          <h3 className="text-2xl text-white">{InitUser().username}</h3>
        </div>
        <div className="flex gap-2 items-center">
          <p className="text-neutral-500 text-lg">email address: </p>
          <h3 className="text-2xl text-white">{InitUser().email}</h3>
        </div>
        <div className="flex gap-2 items-center">
          <p className="text-neutral-500 text-lg">account balance: </p>
          <h3 className="text-2xl text-white font-extrabold">
            ${InitUser().balance.toLocaleString()}
          </h3>
          <button
            className="btn bg-red-500 text-white ml-12 px-4 py-1 rounded-full text-md"
            onClick={() =>
              Paymentinitdialog({
                amt: null,
                bal: InitUser().balance,
                nav: navigation,
                ref: "progressref",
                title: "Topup Account Balance",
                body: null,
              })
            }
          >
            TOP UP BALANCE
          </button>
        </div>
        <div className="flex flex-col gap-2 mt-24">
          <p className="text-white text-lg">invite 5 people to earn rewards</p>

          <OptionsExample />
          {/*  <button
            className="btn bg-green-500 text-white px-4 py-1 rounded-full text-md w-max"
            onClick={() => {}}
          >
            INVITE
          </button> */}
        </div>
      </div>
      <div className="flex flex-col gap-4 w-full">
        <form className="max-w-sm w-full">
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="email"
            >
              Username
            </label>
            <input
              className="shadow appearance-none  rounded w-full py-2 px-3 text-gray-100 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
            />
          </div>
          <div className="">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="password"
            >
              Old Password
            </label>
            <input
              className="shadow appearance-none  rounded w-full py-2 px-3 text-gray-100 mb-3 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Old password"
            />
          </div>
          <div className="">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="cpassword"
            >
              New Password
            </label>
            <input
              className="shadow appearance-none  rounded w-full py-2 px-3 text-gray-100 mb-3 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
              id="cpassword"
              type="password"
              value={newpassword}
              onChange={(e) => setNewpassword(e.target.value)}
              placeholder="New Password"
            />
          </div>

          <div className=" mb-3">
            <label className="block text-red-500 text-sm font-bold mb-2">
              {error}
            </label>
          </div>

          <div className="flex items-center justify-between">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleLogin}
            >
              Update
            </button>
            <Progressdialog isProgress={progress} />
          </div>
          <div className="flex flex-col gap-2 mt-24">
            <p className="text-white text-lg">
              Join our telegram channel for more money making updates
            </p>
            <button
              className="btn bg-blue-500 text-white px-4 py-2 font font-extrabold rounded-full text-md w-max flex gap-2 items-center"
              onClick={() => {
                window.open("https://t.me/+ULE91_JSta9s6KK6", "_blank");
              }}
            >
              {" "}
              <FaTelegram size={20} />
              Join Telegram
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Purchases = () => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    GetADocWhere(purchasecollection, where("uid", "==", InitUser().uid)).then(
      (value) => {
        setItems(value);
      }
    );
  }, []);

  function download(filename, text) {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  return (
    <div className="flex flex-col mt-8">
      <h1 className="text-2xl my-4 text-white">Purchased Tools</h1>
      {items.map((value, index) => (
        <div
          className={`p-2 ${
            index % 2 === 0 ? "bg-neutral-500" : "bg-neutral-600"
          } flex gap-4  items-center justify-between`}
          key={index}
        >
          <p className="text-white">{value.purchasedat}</p>
          <p className="text-white">{value.type}</p>
          {/* //<FaEye onClick={() => {}} /> */}
          <FaDownload
            className="mr-4"
            onClick={() => download(value.type, value.item)}
          />
        </div>
      ))}
    </div>
  );
};

const Helpdesk = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [progress, setProgress] = useState(false);

  const id = UID(10);

  const submitHelp = () => {
    if (email === "") {
      Info({ title: "Error", msg: "Please enter your email address" });
      return false;
    }
    if (subject === "") {
      Info({ title: "Error", msg: "Enter Message Subject" });
      return false;
    }
    if (text === "") {
      Info({
        title: "Error",
        msg: "State your concerns and your problems here",
      });
      return false;
    }

    setProgress(true);
    SetADoc(uniccstoolsreports, id, {
      email,
      subject,
      uid: InitUser().uid,
      id,
    }).then((v) => {
      setEmail("");
      setSubject("");
      setText("");
      setProgress(false);
      Info({
        title: "Success",
        msg: "Message sent, replies woul be sent to the provided emails",
      });
    });
  };
  return (
    <div className="flex flex-col my-4 w-full md:w-4/6 lg:w-2/6">
      <h1 className="text-2xl text-white my-4">Help Desk</h1>

      <div className="flex flex-col gap-4">
        <label htmlFor="email">Email Address</label>
        <input
          className="p-2 outline-none rounded-lg text-black"
          type="email"
          name=""
          id="email"
          placeholder="Enter your active eamil address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="subject">Subject</label>
        <input
          className="p-2 outline-none rounded-lg text-black"
          type="subject"
          name=""
          id="subject"
          placeholder="Enter your help query title here..."
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <label htmlFor="text">Messageee/Text</label>
        <textarea
          className="p-2 outline-none rounded-lg text-black h-56"
          type="text"
          name=""
          id="text"
          placeholder="How may we help you?"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <button
          className="bg-red-500 text-white rounded-full py-2 px-4 my-4"
          onClick={submitHelp}
        >
          Submit
        </button>
        <Progressdialog isProgress={progress} />
      </div>
    </div>
  );
};

const Sales = () => {
  const [email, setEmail] = useState("");

  const submitSales = (e) => {
    if (email === "") {
      Info({
        title: "Error",
        msg: "Your active email address is needed.",
      });
      return;
    }
    SetADoc(uniccstoolsreports, InitUser().uid, {
      email,

      uid: InitUser().uid,
      date: moment().format("LLL"),
    }).then((v) => {
      setEmail("");

      Info({
        title: "Success",
        msg: "You have successfully subscibed to sell your tools on uniccs. Thank you for subscribing.",
      });
    });
  };
  return (
    <div className="flex flex-col my-4">
      <h1 className="text-5xl text-white">
        You can sell your online making tools here
      </h1>
      <h1 className="text-xl text-white my-4">
        Want to get notified? subscribe below.{" "}
      </h1>
     
        <input
          className="p-2 outline-none rounded-lg text-black"
          type="email"
          name=""
          id="email"
          placeholder="Enter your active eamil address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className="bg-red-500 text-white rounded-full py-2 px-4 my-4 w-max"
          onClick={submitSales}
        >
          Submit
        </button>
     
    </div>
  );
};
