import React, { useState, useLayoutEffect } from "react";
import {
    FaCheckCircle,
    FaCopy,
    FaEllipsisH,

    FaQuestionCircle,
    FaStopCircle,
} from "react-icons/fa";
import QRCode from "react-qr-code";
import { useLocation, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import { mainhome, uniccstoolspayment, purchasecollection, userscollection } from "../config/data";
import { Info, InitUser, UID } from "../config/functions";
import { GetPaymentStatus } from "./initpayments";
import { GetADoc, SetADoc, UpdateADoc } from "../config/db";
import Progressdialog from "../components/progres";

var index = 0;

const PaymentGateway = () => {
    const location = useLocation();
    const code = location.state.code;
    /*   const em = location.state.useremail;
    const un = location.state.username; */
    const [payment, setpayment] = useState([]);
    const [pay, setpay] = useState([]);
    const [addresses, setAddersses] = useState([])
    const [coin, setCoin] = useState('bitcoin')
    const [wallet, setWallet] = useState('')
    const [coinprice, setCoinprice] = useState('')
    const [price, setPrice] = useState('')
    const [coinunit, setCoinunit] = useState('')

    const navigation = useNavigate();

    useLayoutEffect(() => {
        const interval = setInterval(() => {
            GetPaymentStatus(code)
                .then((v) => v.text())
                .then((value) => {
                    console.log(JSON.parse(value))

                    value && setpayment(JSON.parse(value));
                    // value && setAddersses([JSON.parse(value).pay_address]);
                    value && setpay([JSON.parse(value)]);
                    if (JSON.parse(value).payment_status === "finished" || JSON.parse(value).payment_status === "partially_paid") {
                        clearInterval(interval)


                        const uid = UID(30)
                        GetADoc(userscollection, InitUser().uid).then(async (data) => {
                            console.log(data.data())

                            const balance = parseInt(data.data().balance) + parseInt(JSON.parse(value).outcome_amount.toFixed(0));
                            console.log(balance)
                            Progressdialog({
                                msg: "Updating your account balance. Please do not close this window.",
                            });
                             await UpdateADoc(userscollection, InitUser().uid, { balance }).then(async () => {
                                 const update = [
                                     {
                                         invitationcode: InitUser().invitationcode,
                                         createdat: InitUser().createdat,
                                         username: InitUser().username,
                                         invitedby: InitUser().invitedby,
                                         balance,
                                         password: InitUser().password,
                                         email: InitUser().email,
                                         uid: InitUser().uid,
                                     },
                                 ];
 
                                 localStorage.setItem(
                                     "user",
                                     JSON.stringify(update)
                                 );
 
                                 await SetADoc(uniccstoolspayment, uid, {
                                     item: 'Account top up',
                                     type: "An amount of $" + parseInt(JSON.parse(value).outcome_amount.toFixed(0)) + ' was added to your account balance',
                                     paymentid: code,
                                     purchasedat: moment().format("LLL"),
                                     uid: InitUser().uid,
                                     docid: uid,
                                     amount: parseInt(JSON.parse(value).outcome_amount.toFixed(0))
                                 }).then((value) => {
 
                                     Info({
                                         title: "Account updated",
                                         msg:
                                             "Your account balance has been topped up with $" +
                                             parseInt(JSON.parse(value).outcome_amount.toFixed(0)) +
                                             " continue to make transactions with us. Enjoy!",
                                     });
                                      navigation(mainhome, { replace: true });
                                 }).catch((err)=>console.log(err));
 
                             }); 
                        });

                        return;
                    }
                })
                .catch((err) => {
                    Info({ title: "Payment Error", msg: err.message });
                });

        }, 10000);

        return () => {
            clearInterval(interval)
        };
    }, []);
    console.log(payment)



    const handlecoin = (e) => {
        setCoin(e.target.value)
        //setWallet(payment.addresses[coin])
        setCoinprice(payment.pricing[coin].amount)
        setCoinunit(payment.pricing[coin].currency)
        setPrice(payment.pricing.local.amount)
    }
    return (
        <div className="flex w-full lg:w-3/6 px-4 justify-center mx-auto">
            (<div className="flex flex-col items-center mx-auto">
                <h1 className="font-extrabold text-2xl lg:text-4xl text-white my-4">
                    Complete Your Transaction
                </h1>
                <div className="rounded-xl p-8 w-full flex flex-col bg-white mt-8">
                    {pay.length > 0 && (
                        <div className="flex flex-col">
                            <h1 className="text-black text-3xl text-center font-extrabold">{payment.payment_id}</h1>
                            <h1 className="text-black text-lg text-center font-bold">{payment.order_id}</h1>
                            <p className="text-md mt-4 text-center">{payment.order_description}</p>
                            <div className="flex flex-col my-8 items-center">
                                <div className="w-max p-4 rounded-lg border-1 border-red-500">
                                    <QRCode
                                        size={120}
                                        value={payment.pay_address}
                                        fgColor={"#CA1717"}
                                    />
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div className="flex flex-col my-4 items-center">
                                        <span className="font-thin text-black/50">Wallet Address</span>
                                        <h1 className="text-lg font-extrabold">{payment.pay_address}</h1>
                                    </div>

                                    <span className="font-thin mb-4 text-center text-black/50">Pay the amount below</span>
                                    <div className="flex gap-2 -mt-6 justify-center items-center">

                                        <h1 className="text-3xl font-bold">${parseFloat(payment.outcome_amount).toFixed(0)}</h1>
                                        <h1 className="text-lg font-extrabold">{payment.pay_currency.toUpperCase()} <span className="text-3xl font-extrabold"> {parseFloat(payment.pay_amount).toFixed(4)}</span></h1>
                                    </div>
                                    <div className="flex flex-col gap-2 items-center">
                                        <div className="flex gap-2 items-center">

                                            <strong>Payment Status:</strong>
                                            <Status
                                                status={payment.payment_status}
                                                amount={parseFloat(payment.outcome_amount).toFixed(0)}
                                                code={code}
                                                navigation={navigation}
                                            />
                                        </div>
                                        {pay.length > 0 && <button className="bg-red-600 text-white py-2 px-4 my-8 rounded-xl"
                                            onClick={(e) => {

                                            }}
                                        >Continue</button>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    )}
                    {pay.length < 1 && (
                        <div className="flex justify-center">
                            <div
                                className="spinner-border animate-spin inline-block w-24 h-24 border-4 rounded-full text-black-100"
                                role="status"
                            >
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    )}
                </div>
            </div> )
        </div >
    );
};

export default PaymentGateway;



const Status = ({ status, code, navigation, amount }) => {
    if (status === "waiting") {
        return (
            <div className="flex gap-2">
                <h1 className="text-red-500">Waiting</h1>
                <div
                    className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                    role="status"
                >
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }
    if (status === "confirmed") {

        return (
            <div className="flex gap-2 items-center">
                <h1 className="text-green-800">CONFIRMED</h1>
                <FaCheckCircle className="text-green-800" size={25} />

            </div>
        );
    }
    if (status === "finished") {

        return (
            <div className="flex gap-2">
                <h1 className="text-green-800">{status}</h1>
                <FaCheckCircle className="text-green-800" size={25} />
                <button className="bg-green-600 text-black my-2 mx-4 rounded-lg"
                    onClick={() => {

                    }}
                >Continue</button>
            </div>
        );
    }
    if (status === "failed") {
        return (
            <div className="flex gap-2">
                <h1 className="text-red-100">{status}</h1>
                <FaStopCircle className="text-red-100" size={25} />
            </div>
        );
    }
    if (status === "expired") {
        return (
            <div className="flex gap-2">
                <h1 className="text-red-900">{status}</h1>
                <FaStopCircle className="text-red-900" size={25} />
            </div>
        );
    }
    if (status === "confirming") {
        return (
            <div className="flex gap-2">
                <h1 className="text-orange-600">{status}</h1>
                <FaEllipsisH className="text-orange-600" size={25} />
            </div>
        );
    }
    if (status === "sending") {
        return (
            <div className="flex gap-2">
                <h1 className="text-red-600">{status}</h1>
                <FaEllipsisH className="text-red-600" size={25} />
            </div>
        );
    }
    if (status === "partially_paid") {


        return (
            <div className="flex gap-2">
                <h1 className="text-red-900">
                    {"Payment not fully made "} ${amount} only
                </h1>
                <FaQuestionCircle className="text-red-900" size={25} />
            </div>
        );
    }
};

