import React from "react";
import CategoryComponent from "../components/category";
import Navbar from "../components/navbar";
import Mobilenav from "../components/mobilenav";
import { UserExist } from "../config/functions";
import { Helmet } from "react-helmet";
const CategoryPage = () => {
  let x = setTimeout(() => {
    if (localStorage.getItem("user") !== null) {
      clearInterval(x);
      UserExist.exist = true;
    }
  }, 2000);
  return (
    <div className="w-full md:w-5/6 lg:w-3/6">
      <Helmet>
        <meta charset="utf-8" />
        
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Signup or login to the altimate one stop shop for all your hacking tools; cryptocurrency, bank logs, bank cheque, carding, spamming, bank accounts, vpn, socks5, etc, rdp"
        />
        <meta
          name="keyword"
          content="cryptocurrency, bank logs, bank cheque, carding, spamming, bank, account, hacking, vpn, socks5, rdp, crytocurrency, bitcoin, ethereum, solana, tether, usdt"
        />
        <link rel="canonical" href="https://uniccs.cc/categoricalservices" />
        <titie>Crytocurrency Accounts Page - Banklogs, bank, checks, cheques, bitcoin,ethereum, solana, tether, usdt</titie>
      </Helmet>
      <div className="mt-28">
        <CategoryComponent />
      </div>
    </div>
  );
};

export default CategoryPage;
