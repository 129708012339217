import React from "react";
import CreditCardShopComponent from "../components/creditcardcomponent";
import Navbar from "../components/navbar";
import Mobilenav from "../components/mobilenav";
import { UserExist } from "../config/functions";
import { Helmet } from "react-helmet";
const CreditCardPage = () => {
  let x = setTimeout(() => {
    if (localStorage.getItem("user") !== null) {
      clearInterval(x);
      UserExist.exist = true;
    }
  }, 2000);
  return (
    <div className="w-full md:w-5/6 lg:w-5/6">
      <Helmet>
        <meta charset="utf-8" />
        
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Find all your hacking tools and material that you'll need to make some cool bucks on the internet with less ease. Tools like cryptocurrency tranaction reversal, credit card to bitcoin or cctobtc, buy crypto with backlog, bank logs, bank cheque, carding, spamming, bank accounts, vpn, socks5, etc, rdp"
        />
        <meta
          name="keyword"
          content="non vbv, cctobtc, cryptocurrency, bank logs, bank cheque, carding, spamming, bank, account, hacking, vpn, socks5, rdp, cctobtc, cc2btc, carding, loading, cryptocurrency"
        />
        <link rel="canonical" href="https://uniccs.cc/nonverifiedcreditcardshop" />
        <titie>
          Credit Card HomePage - Non-verified crdit cards bank, checks, chequs,
          paypal, cctobtc
        </titie>
      </Helmet>
      <div className="mt-28">
        <CreditCardShopComponent />
      </div>
    </div>
  );
};

export default CreditCardPage;
