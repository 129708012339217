import React from "react";
import SocialMediaComponent from "../components/socialmediacomponent";
import { Helmet } from "react-helmet";
const SocialMediaPage = () => {
  return (
    <div className="w-full  md:w-5/6 lg:w-5/6">
      <Helmet>
        <meta charset="utf-8" />
        
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Grow your social media accounts by getting followers, video or post likes, share, comment. We offer intanst result and all our services are organic and remains permanent"
        />
        <meta
          name="keyword"
          content="twitter, instagram, facebook, telegram, snapchat, social media, account, likes, follow, comment, share,"
        />
        <link
          rel="canonical"
          href="https://uniccs.cc/socialmediaaccountbooster"
        />
        <titie>Social Media Growth Page - Banklogs, bank, checks, chequs</titie>
      </Helmet>
      <div className="mt-28">
        <SocialMediaComponent />
      </div>
    </div>
  );
};

export default SocialMediaPage;
