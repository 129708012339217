import React from "react";
import ShoppingScriptComponent from "../components/shoppingscriptcomponent";
import { Helmet } from "react-helmet";
const ShoppingScriptPage = () => {
  return (
    <div className="w-full  md:w-5/6 lg:w-5/6">
      <Helmet>
        <meta charset="utf-8" />
        
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Get big discount of amazon products upto 95%. We have instock all kinds of scripts which you can cahsout; shop with script, bank logs, bank cheque, carding, spamming, refund scirpt, fundscript, discount amazon slash"
        />
        <meta
          name="keyword"
          content="amazon, product, 100% discount, shop with script, cryptocurrency, bank logs, bank cheque, carding, spamming, bank, account, hacking, vpn, socks5, rdp"
        />
        <link rel="canonical" href="https://uniccs.cc/shoppingscripts" />
        <titie>Shopping Script Page - Amazon discount, amazon products, shop with script</titie>
      </Helmet>
      <div className="mt-28">
        <ShoppingScriptComponent />
      </div>
    </div>
  );
};

export default ShoppingScriptPage;
