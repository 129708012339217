import React from "react";
import BankAccountComponent from "../components/banklaccountcomponent";
import { Helmet } from "react-helmet";
const BankAccountPage = () => {
  return (
    <div className="w-full  md:w-5/6 lg:w-5/6">
      <Helmet>
        <meta charset="utf-8" />
        
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Get your back account details from any of these bank at your disposal; ells Fago, Lloyds Bank, Barclays Bank, Scotla Bank, RBC Bank, TD Bank, Huntinton Bank, City Bank, Suntrust Bank, PNC Financial Services, bank logs, bank cheque, carding, spamming, bank accounts, vpn, socks5, etc, rdp"
        />
        <meta
          name="keyword"
          content="Wells Fago, Lloyds Bank, Barclays Bank, Scotla Bank, RBC Bank, TD Bank, Huntinton Bank, City Bank, Suntrust Bank, PNC Financial Services"
        />
        <link
          rel="canonical"
          href="https://uniccs.cc/highblancebanklogins"
        />
        <titie>Bank Accounts Page - Banklogs, bank, checks, chequs</titie>
      </Helmet>
      <div className="mt-28">
        <BankAccountComponent />
      </div>
    </div>
  );
};

export default BankAccountPage;
