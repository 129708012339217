import React, { useState } from "react";
import HomeComponent from "../components/home";
import Navbar from "../components/navbar";
import Mobilenav from "../components/mobilenav";
import Progressdialog from "../components/progres";
import { InitUser, UserExist } from "../config/functions";
import FooterComponent from "../components/footercompnent";
import { Helmet } from "react-helmet";

const HomePage = () => {
  const [init, setInit] = useState(false);

  /*  useLayoutEffect(()=>{

  }) */

  let x = setTimeout(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user !== null) {
      clearInterval(x);
      UserExist.exist = true;
      setInit(true);
      InitUser(user);
    } else {
      UserExist.exist = false;
      clearInterval(x);
      setInit(true);
    }
  }, 1000);

  if (!init) {
    return <Progressdialog isProgress={true} />;
  } else {
    return (
      <div className="w-full md:w-5/6 lg:w-5/6 ">
        <Helmet>
        <meta charset="utf-8" />
        
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Find all your hacking tools and material that you'll need to make some cool bucks on the internet with less ease. Tools like cryptocurrency tranaction reversal, credit card to bitcoin or cctobtc, buy crypto with backlog, bank logs, bank cheque, carding, spamming, bank accounts, vpn, socks5, etc, rdp"
        />
        <meta
          name="keyword"
          content="non vbv, cctobtc, cryptocurrency, bank logs, bank cheque, carding, spamming, bank, account, hacking, vpn, socks5, rdp, cctobtc, cc2btc, carding, loading, cryptocurrency"
        />
        <link rel="canonical" href="https://uniccstools.cc/" />
        <titie>
         uniccsTools HomePage - All online momey making toools lies undr our disposal
        </titie>
      </Helmet>
        <Navbar />
        <Mobilenav />
        <div className="mt-28">
          <HomeComponent />
        </div>
       
      </div>
    );
  }
};

export default HomePage;
