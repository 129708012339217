/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import MaterialReactTable from "material-react-table";
import { useLocation, useNavigate } from "react-router-dom";
import { FaShoppingCart } from "react-icons/fa";

import {
  Confirm,
  GenCC,
  GenFullz,
  Gendocs,
  Info,
  InitUser,
  UID,
  UserExist,
  bankcols,
  cccol,
  def,
  fullzcol,
} from "../config/functions";
import { ccs } from "../temp/ccs";
import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import Mobilenav from "./mobilenav";
import Navbar from "./navbar";
import { GetADoc, SetADoc, UpdateADoc } from "../config/db";
import { userscollection, purchasecollection, auth } from "../config/data";
import moment from "moment/moment";
import Progressdialog from "./progres";
import { Paymentinitdialog } from "../payments/initpayments";
import { banklogs } from "../temp/logs";
import { sd } from "../temp/sd";
import { anonymity } from "../temp/anonymity";

const CryptocurrencyCompnent = () => {
  const location = useLocation();

  const title = location.state.title;
  const cat = location.state.cat;
  const id = location.state.index;
  const lg = anonymity.filter((v) => v.type === cat);

  const dats = [lg];
  const retcol = () => def;//check to original
  const retdat = () => dats[0];
  const [progress, setProgress] = useState(0);
  const [isProgress, setIsprogress] = useState(false);
  const columns = useMemo(() => retcol() ?? def, [retcol]);

  const navigation = useNavigate();
  const progressref = useRef();

  
  //Gendocs();
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark", //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default: "#000", //pure black table in dark mode for fun
          },
        },
      }),
    [globalTheme]
  );

  return (
    <div className="flex flex-col justify-center text-gray-300 bg-black bg-opacity-60 px-4 md:px-2 lg:px-0">
      <Navbar title={title} cat={cat} />
      <Mobilenav />
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          columns={columns}
          initialState={{
            pagination: { pageIndex: 0, pageSize: 50 },
          }}
          data={retdat() ?? []}
          muiLinearProgressProps={({ isTopToolbar }) => ({
            color: "primary",
            variant: "determinate", //if you want to show exact progress value
            value: progress, //value between 0 and 100
            sx: {
              //hide bottom progress bar
            },
          })}
          muiTableBodyCellProps={({ cell, column, row }) => ({
            onClick: (event) => {
              //console.info("cell: ", column.id, row.id);
              if (UserExist.exist === true) {
              if (column.id.includes("price")) {
                var docid = row.id;
                var price = cell.getValue(cell.id);
                setIsprogress(true);
                GetADoc(userscollection, InitUser().uid).then((value) => {
                  setIsprogress(false);

                  if (value.data().balance > price) {
                    Confirm({
                      title: cat,
                      msg: "Are you sure you want to proceed with this transaction?",
                      action: () => {
                        setIsprogress(true);
                        MakePurchase(
                          lg[docid],
                          price,
                          value.data(),
                          cat,
                          navigation,
                          progressref
                        )
                          .then((v) => {
                            setIsprogress(false);
                            Info({
                              title: "Success",
                              msg: "Transaction has beeen traded successfully, thank you for your purchase and hope to see you again soon.",
                            });
                          })
                          .catch((e) => {
                            setIsprogress(false);
                            console.log(e);
                            Info({ title: "Error", msg: e.toString() });
                          });
                      },
                    });
                    return;
                  } else {
                    Confirm({
                      title: "Low Account Balance",
                      msg: "Your account balance is low to complete this transaction, topup your account now?",
                      action: () => {
                        setTimeout(() => {
                          Paymentinitdialog({
                            amt: null,
                            bal: value.balance,
                            nav: navigation,
                            ref: progressref,
                            title: "Topup Account Balance",
                            body: null,
                          });
                        }, 500);
                      },
                    });
                  }
                });
              }} else {
                Confirm({
                  title: cat,
                  msg: "You are not logged in, continue with authentication process?",
                  action: () => {
                    navigation(auth)
                  },
                });
              }
            },
            sx: {
              cursor: "pointer",
            },
          })}
          positionActionsColumn="last"
          state={{ showProgressBars: true }}
        />
      </ThemeProvider>
      <Progressdialog isProgress={isProgress} />
    </div>
  );
};

export default CryptocurrencyCompnent;

const formatCardNumber = (value) => {
  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
  const onlyNumbers = value.replace(/[^\d]/g, "");

  return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
    [$1, $2, $3, $4].filter((group) => !!group).join(" ")
  );
};

export const MakePurchase = async (
  doc,

  price,
  user,
  title,
  navigation,
  progressref
) => {
  var balance = user.balance;
  var uid = UID();

  const bal = balance - price;
  const item = doc;
  const format = `${title}
 
    *********************BANK ACCOUNT LOGIN AND ACCOUNT DETIALS*********************\n
     Name: ${item.fullname}
     Email: ${item.email}
     Address: ${item.address}
     Password: ${item.password}
     Account Balance: $${item.balance}
     Bank Name: ${item.bank}
     Account Type: ${item.type}
     SSN: ${item.ssn}
     Routing Code: ${item.routing}
       
    \n********************************************************************************************************\n
   `;
  await UpdateADoc(userscollection, user.uid, { balance: bal });
  await SetADoc(purchasecollection, uid, {
    item: item.item,
    type: title,
    purchasedat: moment().format("LLL"),
    uid: user.uid,
    docid: uid,
  });
  const update = [
    {
      invitationcode: user.invitationcode,
      createdat: user.createdat,
      username: user.username,
      invitedby: user.invitedby,
      balance: bal,
      password: user.password,
      email: user.email,
      uid: user.uid,
    },
  ];
  download(`${title}.txt`, item.item);
  InitUser(update);
  localStorage.setItem("user", JSON.stringify(update));
  return;

  function download(filename, text) {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
};
