import React from "react";
import TutorialComponent from "../components/tutorialcomponent";
import { Helmet } from "react-helmet";

const TutorialPage = () => {
  return (
    <div className="w-full  md:w-5/6 lg:w-5/6">
      <Helmet>
        <meta charset="utf-8" />
        
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Learn all about how to make money online, hacking tools, cryptocurrency, bank logs, bank cheque, carding, spamming, bank accounts, vpn, socks5, etc, rdp. we offer written and video based tutorials to help you."
        />
        <meta
          name="keyword"
          content="vidoe, tutorial, cryptocurrency, bank logs, bank cheque, carding, spamming, bank, account, hacking, vpn, socks5, rdp"
        />
        <link rel="canonical" href="https://uniccs.cc/howtomakewithlittleornoknowledge" />
        <titie>
          Tutorials Page - Hacking, Banklogs, bank, checks, chequs
        </titie>
      </Helmet>
      <div className="mt-28">
        <TutorialComponent />
      </div>
    </div>
  );
};

export default TutorialPage;
