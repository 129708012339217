import React, { useState } from "react";
import Nabbar from "./navbar";

import { useLocation, useNavigate } from "react-router-dom";
import { category, store, userscollection } from "../config/data";

import Navbar from "./navbar";
import Mobilenav from "./mobilenav";
import Progressdialog from "./progres";
import { GetADoc } from "../config/db";
import { Confirm, GenBankLogs, Gendocs, InitUser } from "../config/functions";

const CategoryComponent = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const title = location.state.title;
  const data = location.state.data;
  const items = location.state.items;
  const page = location.state.page;
  const [progress, setProgress] = useState(false);
 
  return (
    <div className="flex flex-col justify-center text-gray-300 bg-black bg-opacity-60 px-4 md:px-2 lg:px-0">
      <Navbar />
      <Mobilenav />
    
      <div className="flex justify-center my-8">
        <h1 className="text-center text-sm md:text-md text-white">
          Browse through all the availabe {title} categories and find which one
          will suite your online transactions. If you have any of these {title}{" "}
          items and wish to make money from them, you can sell them here but
          remember to comply to our Terms & Condition as well as to follow our
          Privacy Policies.
        </h1>
      </div>
      <div className="grid grid-cols-3 md:grid-cols-5 gap-8 ">
        {data.map((value, index, array) => (
          <div
            className="flex flex-col my-8 items-center hover:cursor-pointer"
            key={index}
            onClick={() => {
              setProgress(true);
              

              setTimeout(() => {
                navigation(page, {
                  state: {
                    title,
                    items,
                    cat: value.title,
                    index: index,
                  },
                });
              }, 1000);
            }}
          >
            <img
              src={value.icon}
              alt={value.title}
              className="object-scale-down mb-8 h-24 hover:filter hover:sepia hover:cursor-pointer hover:transition-all hover:delay-100"
            />
            <h2 className="text-sm text-gray-300 text-center text-opacity-70 hover:text-opacity-100 hover:transition-all hover:delay-100">
              {value.title}
            </h2>
          </div>
        ))}
        <Progressdialog isProgress={progress} />
      </div>
    </div>
  );
};

export default CategoryComponent;
