import React, { useState } from "react";
import Nabbar from "./navbar";
import { ParentTool, category } from "../config/data";
import Mobilenav from "./mobilenav";
import { useNavigate } from "react-router-dom";
import { nonvbvcardsfullzgenerator } from "../temp/creditcards";
import Progressdialog from "./progres";

const HomeComponent = () => {
  const navigation = useNavigate();
  const [tool, setTool] = useState("");
  const [title, setTitle] = useState(ParentTool()[0].title);
  const [items, setItems] = useState();
  const [mobileIndex, setMobileIndex] = useState(0);
  const [desktopIndex, setDesktopIndex] = useState(0);
  const [page, setPage] = useState(ParentTool()[0].page);
  const [data, setData] = useState(ParentTool()[0].data);
  const [progress, setProgress] = useState(false);

  const handlechangetools = (e) => {
    setTool(ParentTool()[e.target.value].title);
    setData(ParentTool()[e.target.value].data);
    setPage(ParentTool()[e.target.value].page);
    setTitle(ParentTool()[e.target.value].title);
    setMobileIndex(parseInt(e.target.value));
  };


  

  return (
    <div className="flex flex-col lg:flex-row gap-8 justify-center text-gray-300 px-4 md:px-2 lg:px-0">
      <div className="hidden lg:flex order-2 flex-col gap-4 w-full md:w-3/4 xl:w-1/3 lg:order-1 ">
        {ParentTool().map((value, index, array) => (
          <div
            className="flex items-center hover:cursor-pointer"
            key={index}
            onClick={() => {
              // setItems(value.items)
              setTool(ParentTool()[index].title);
              setData(ParentTool()[index].data);
              setPage(ParentTool()[index].page);
              setTitle(ParentTool()[index].title);
              setDesktopIndex(index);
            }}
          >
            {/* <img
              src={value.icon}
              alt={value.title}
              className="mb-8 w-8 hover:filter hover:sepia hover:cursor-pointer hover:transition-all hover:delay-100"
            /> */}

            <div
              className={`${
                desktopIndex === index ? "bg-red-50" : ""
              }0  px-2 rounded md`}
            >
              <h2
                className={`text-sm ${
                  desktopIndex === index ? "text-white" : "text-gray-300"
                } text-opacity-70 text-left hover:text-opacity-100 hover:transition-all lg:text-md font-extrabold py-1 hover:delay-100`}
              >
                {value.title}
              </h2>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center order-2 flex-col items-center gap-4 w-full lg:hidden text-black bg-opacity-60 font-bold border-one outline-one">
        <select
          className="border-none outline-none rounded-lg py-2 px-4 w-4/5 md:w-3/6 xl:5/6"
          name="tools"
          id=""
          value={tool}
          onChange={handlechangetools}
        >
          <option value={0}>Select Tool</option>
          {ParentTool().map((value, index, array) => (
            <option value={index} key={value.title}>
              {value.title}
            </option>
          ))}
        </select>
      </div>
      <div className="flex flex-col order-4 justify-center text-gray-300 bg-black  bg-opacity-60 px-4 md:px-2 lg:px-0 lg:hidden">
        <div className="flex justify-center my-8">
          <h1 className="text-center text-sm md:text-md text-white -mt-2">
            Browse through all the availabe {title} categories and find which
            one will suite your online transactions. If you have any of these{" "}
            {title} items and wish to make money from them, you can sell them
            here but remember to comply to our Terms & Condition as well as to
            follow our Privacy Policies. 
          </h1>
        </div>
        <div className="grid grid-cols-3 md:grid-cols-4  gap-8 ">
          {data.map((value, index, array) => (
            <div
              className="flex flex-col my-4 items-center hover:cursor-pointer"
              key={index}
              onClick={() => {
                setPage(ParentTool()[mobileIndex].page);
                setProgress(true);
                
                setPage(ParentTool()[mobileIndex].page);
                setTimeout(() => {
                  navigation(page, {
                    state: {
                      title,
                      items,
                      cat: value.title,
                      index: index,
                    },
                  });
                }, 1000);
              }}
            >
              <img
                src={value.icon}
                alt={value.title}
                className="object-scale-down mb-4 h-12 filter grayscale red hover:filter hover:sepia hover:cursor-pointer hover:transition-all hover:delay-100"
              />
              <h2 className="text-sm text-gray-300 text-center text-opacity-70 hover:text-opacity-100 hover:transition-all hover:delay-100">
                {value.title}
              </h2>
            </div>
          ))}
          <Progressdialog isProgress={progress} />
        </div>
      </div>

      <div className="flex flex-col order-1 my-2">
        <h1 className="text-justify md:text-center text-sm md:text-md lg:text-xl text-white">
          We provide you with the hacking tools you've ever wanted. Bolow are
          the categories that will lead you to the materials and tools. Non-VBV
          high balance credit cards, rdp, bank logs, bank account logins, bank
          checque, crypto transaction reversal, social security number (SSN)
          employee identification number (EIN), fullz... etc.
        </h1>
        <div className="hidden lg:flex flex-col order-4 justify-center text-gray-300 bg-black bg-opacity-60  px-4 md:px-2 lg:px-0 ">
          <div className="flex justify-center my-8">
            <h1 className="text-center text-sm md:text-md lg:text-lg text-white ">
              Browse through all the availabe {title} categories and find which
              one will suite your online transactions. If you have any of these{" "}
              {title} items and wish to make money from them, you can sell them
              here but remember to comply to our Terms & Condition as well as to
              follow our Privacy Policies. 1
            </h1>
          </div>
          <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-8 ">
            {data.map((value, index, array) => (
              <div
                className="flex flex-col my-4 items-center hover:cursor-pointer"
                key={index}
                onClick={() => {
                  setPage(ParentTool()[desktopIndex].page);
                  setProgress(true);
                  
                  setPage(ParentTool()[desktopIndex].page);
                  setTimeout(() => {
                    navigation(page, {
                      state: {
                        title,
                        items,
                        cat: value.title,
                        index: index,
                      },
                    });
                  }, 1000);
                }}
              >
                <img
                  src={value.icon}
                  alt={value.title}
                  className="object-contain invert-[.56] sepia-[.98] saturate-[49.77] hue-rotate-[335deg] brightness-[.97] contrast-[.93] mb-4 h-16 red hover:filter hover:sepia hover:cursor-pointer hover:transition-all hover:delay-100"
                />
                <h2 className="text-sm text-gray-300 text-center text-opacity-70 hover:text-opacity-100 hover:transition-all hover:delay-100">
                  {value.title}
                </h2>
              </div>
            ))}
            <Progressdialog isProgress={progress} />
          </div>
        </div>
      </div>
      <div className="hidden order-3 xl:flex  flex-col gap-4 w-full md:w-3/5 lg:order-4">

      <h1 className="text-sm md:text-lg text-white leading-10 text-justify">
          Welcome to our virtual store, where we offer a wide range of products
          and services to meet all of your hacking and cybercrime needs. Our
          inventory includes RDP, non-verified credit cards, bank logs, hacking
          tutorials, shopping scripts, bank abount details and social media
          growth services and a lot more. We also provice the opportunity to
          sell your digital goods which compliance with our terms and conditions.
          We pride ourselves on offering top-quality products and services at
          competitive prices, and we're committed to helping our customers
          achieve their goals...
        </h1>
      </div>
    </div>
  );
};

export default HomeComponent;
