import React from "react";
import CreditCardShopComponent from "../components/creditcardcomponent";
import Navbar from "../components/navbar";
import Mobilenav from "../components/mobilenav";
import BankLogShopComponent from "../components/banklogscomponent";
import { Helmet } from "react-helmet";
const BanklogPage = () => {
  return (
    <div className="w-full  md:w-5/6 lg:w-5/6">
      <Helmet>
        <meta charset="utf-8" />
        
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="The times you need a bank login to make a quick cash or bucks becomes a bit hectic, don' worry we've got American bank logins whic will get you covered; Wells Fago, Lloyds Bank, Barclays Bank, Scotla Bank, RBC Bank, TD Bank, Huntinton Bank, City Bank, Suntrust Bank, PNC Financial Services, Chime Bank, Woodforest Bank, Chase Bank, NFCU Bank, BBVA Bank, Bank of America Bank, BB&T Bank bank logs, bank cheque, carding, spamming, bank accounts, vpn, socks5, etc, rdp"
        />
        <meta
          name="keyword"
          content="Wells Fago, Lloyds Bank, Barclays Bank, Scotla Bank, RBC Bank, TD Bank, Huntinton Bank, City Bank, Suntrust Bank, PNC Financial Services, bank logs, bank cheque, carding, spamming, bank, account, hacking, vpn, socks5, rdp"
        />
        <link
          rel="canonical"
          href="https://uniccs.cc/highblancebanklogins"
        />
        <titie>
          Bank Logs | Banklogs Page - Banklogs, bank, checks, chequs
        </titie>
      </Helmet>
      <div className="mt-28">
        <BankLogShopComponent />
      </div>
    </div>
  );
};

export default BanklogPage;
