import { confirmAlert } from 'react-confirm-alert';
import '../react-confirm-alert.css';
import axios from 'axios';
import { cards } from '../temp/cardfile';
import { account } from '../temp/accountfile';
import { FaShoppingCart } from 'react-icons/fa';
import { IC } from '../temp/invitation';
import { driverlincens, drop } from './scanneddocs';


class Existence {
    contructor(exist) {
        this.exist = exist;
    }

    get state() {
        return this.exist
    }
    set state(state) {
        this.exist = state
    }
}

export const InitUser = () => {
    const list = JSON.parse(localStorage.getItem("user")) ?? [{
        username: '',
        invitationcode: '',
        email: '',
        password: '',
        createdat: '',
        balance: 0,
        invitedby: '',
        uid: ''
    }]
    return {
        username: list[0].username,
        invitationcode: list[0].invitationcode,
        email: list[0].email,
        password: list[0].password,
        createdat: list[0].createdat,
        balance: list[0].balance ?? 0,
        invitedby: list[0].invitedby,
        uid: list[0].uid
    }
}

export const UserExist = new Existence(false);


export const Confirm = (list) => {
    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        customUI: ({ title, onClose }) => {
            return (
                <div div className='mx-auto p-4 max-w-md  text-black bg-white m-6 lg:w-max lg:h-max ' style={{ zIndex: '99999' }}>
                    <h1 className='font-bold text-lg mb-5'>{list.title}</h1>

                    <p className='mb-3'>{list.msg}</p>
                    <div className="flex gap-4 justify-end p-2">

                        <button className='font-medium' onClick={onClose}>Close</button>

                        <button
                            className='font-bold'
                            onClick={() => {
                                list.action();
                                onClose();
                            }}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            );
        }
    });
}
export const Progress = (list) => {
    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        customUI: ({ title, onClose }) => {
            if (list.stats) {
                onClose()
            }
            return (
                <div div className='mx-auto p-4 max-w-md  text-black bg-white m-6 lg:w-max lg:h-max ' style={{ zIndex: '99999' }}>
                    <h1 className='font-bold text-lg mb-5'>{list.title}</h1>
                    <div className="flex justify-center lis-center">
                        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full mb-3" role="status">
                        </div>
                    </div>
                    <p className='mb-3'>{list.message}</p>
                </div>);
        }
    })
}
export const Info = (list) => {
    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        customUI: ({ title, onClose }) => {
            return (
                <div className='mx-auto p-4 max-w-md  text-black bg-white m-6 lg:w-max lg:h-max ' style={{ zIndex: '99999' }}>
                    <h1 className='font-bold text-lg mb-5'>{list.title}</h1>

                    <p className='mb-3'>{list.msg}</p>
                    <div className="flex gap-4 justify-end p-2">
                        <button className='font-medium' onClick={onClose}>Close</button>
                    </div>
                </div>
            );
        }
    });
}

export const PaymentAPI = async (amt) => {

    const url = 'https://api.commerce.coinbase.com/charges';
    const options = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'X-CC-Version': process.env.REACT_APP_VERSION,
            'X-CC-Api-KEY': process.env.REACT_APP_PAY,
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            local_price: { amount: amt, currency: 'USD' },
            metadata: { customer_id: 'skldfasdlfaf', customer_name: 'afdfadsf' },
            name: 'PACKEDTOOLS',
            description: 'Topup your Crypto transaction receipt generator account balance in able to make purchase',
            pricing_type: 'fixed_price'
        })
    };

    return await fetch(url, options)

}
export const CheckPaymentAPI = async (code) => {
    const fetch = require('node-fetch');

    const url = 'https://api.commerce.coinbase.com/charges/' + code;
    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            'X-CC-Version': process.env.REACT_APP_VERSION,
            'X-CC-Api-KEY': process.env.REACT_APP_PAY,
            'content-type': 'application/json'
        }
    };

    return await fetch(url, options)

}
export const ReceiveEmail = async (e, api) => {
    var receipient = {
        "personalizations": [
            {
                "to": [
                    {
                        "email": `${e.send.to}`
                    }
                ],
                "subject": `${e.send.subject}`
            }
        ],
        "from": {
            "email": `${e.send.from} <${e.send.email}>`
        },
        "content": [
            {
                "type": "text/html",
                "value": `${e.send.data}`
            }
        ]
    }


    const options1 = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'X-RapidAPI-Key': api,
            'X-RapidAPI-Host': 'rapidprod-sendgrid-v1.p.rapidapi.com'
        },
        body: JSON.stringify(receipient)
    };

    await fetch("https://rapidprod-sendgrid-v1.p.rapidapi.com/mail/send", options1).then(v => v.json()).then((v) => console.log("sent")).catch((err) => console.log(err.message))

}
export const SendEmail = async (e, api) => {

    var sender = {
        "personalizations": [
            {
                "to": [
                    {
                        "email": `${e.recieve.to}`
                    }
                ],
                "subject": `${e.recieve.subject}`
            }
        ],
        "from": {
            "email": `${e.recieve.from} <${e.recieve.email}>`
        },
        "content": [
            {
                "type": "text/html",
                "value": `${e.recieve.data}`
            }
        ]
    }

    const options2 = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'X-RapidAPI-Key': api,
            'X-RapidAPI-Host': 'rapidprod-sendgrid-v1.p.rapidapi.com'
        },
        body: JSON.stringify(sender)
    }

    await fetch("https://rapidprod-sendgrid-v1.p.rapidapi.com/mail/send", options2).then(v => v.json()).then((v) => {
        console.log("sent")
        Info({ title: "Success", msg: "Transaction completed successfully" })
    }
    ).catch((err) => console.log(err.message))

}

export const TXS = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
export const FXS = (length) => {
    var result = '';
    var characters = '0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const Moments = (date) => {
    if (typeof date !== 'object') {
        date = new Date(date);
    }

    var seconds = Math.floor((new Date() - date) / 1000);
    var intervalType;

    var interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
        intervalType = 'year';
    } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            intervalType = 'month';
        } else {
            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
                intervalType = 'day';
            } else {
                interval = Math.floor(seconds / 3600);
                if (interval >= 1) {
                    intervalType = "hour";
                } else {
                    interval = Math.floor(seconds / 60);
                    if (interval >= 1) {
                        intervalType = "minute";
                    } else {
                        interval = seconds;
                        intervalType = "second";
                    }
                }
            }
        }
    }

    if (interval > 1 || interval === 0) {
        intervalType += 's ago';
    } else {
        intervalType += ' ago';
    }

    return interval + ' ' + intervalType;
};


export const ExchangeRage = async () => {
    const options = {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': '1080ba1908msh027057859d83ed0p13ac36jsn0ac1373f9e2d',
            'X-RapidAPI-Host': 'coingecko.p.rapidapi.com'
        }
    };

    return await fetch('https://coingecko.p.rapidapi.com/exchange_rates', options)

}

export const GenRandom = (len, array) => {
    const list = []
    for (var i = 0; i < len; i++) {
        list.push(array[Math.floor(Math.random() * array.length)]);
    }
    return list
}


export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
/* export const balance = () => {
    return getRandomInt(700, 10000)
}
export const price = (amt) => {
    return (((amt * 0.0025) + 0.05).toFixed(0)) * 1
} */
export const accdob = () => {
    var date = getRandomInt(1, 31)
    const month = getRandomInt(1, 12)
    const year = getRandomInt(1970, 1996)
    if (month === 2 && date > 29) {
        date = date - 2;
        return `${date}/${month}/${year}`
    } else if ((month === 4 && date > 30) || (month === 9 && date > 30) || (month === 11 && date > 30) || (month === 6 && date > 30)) {
        date = date - 1;
        return `${date}/${month}/${year}`
    }
    return `${date}/${month}/${year}`
}
export const expirydate = () => {
    var date = getRandomInt(1, 31)
    const month = getRandomInt(1, 12)
    const year = getRandomInt(2023, 2028)
    if (month === 2 && date > 29) {
        date = date - 2;
        return `${date}/${month}/${year}`
    } else if ((month === 4 && date > 30) || (month === 9 && date > 30) || (month === 11 && date > 30) || (month === 6 && date > 30)) {
        date = date - 1;
        return `${month}/${year}`
    }
    return `${month}/${year}`
}
export const ein = () => {

    var first = getRandomInt(10, 99)
    const second = getRandomInt(1000000, 9999999)
    return `${first}-${second}`
}
export const einno = () => {

    var first = getRandomInt(10, 99)
    const second = getRandomInt(1000000, 9999999)
    return `${first}-${second}`
}
export const ssn = () => {
    var first = getRandomInt(100, 999)
    const second = getRandomInt(10, 99)
    const third = getRandomInt(1000, 9999)

    return `${first}-${second}-${third}`
}


export const CARD = (length) => {
    var result = '';
    var characters = '0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}



export const GenBankLogs = () => {
    const list = []
    const ps = ['123456',
        'password',
        '12345678',
        'qwerty',
        '123456789',
        '12345',
        '1234',
        '111111',
        '1234567',
        'dragon',
        '123123',
        'baseball',
        'abc123',
        'football',
        'monkey',
        'letmein',
        '696969',
        'shadow',
        'master',
        '666666',
        'qwertyuiop',
        '123321',
        'mustang',
        '1234567890',
        'michael',
        '654321',
        'pussy',
        'superman',
        '1qaz2wsx',
        '7777777',
        'fuckyou',
        '121212',
        '000000',
        'qazwsx',
        '123qwe',
        'killer',
        'trustno1',
        'jordan',
        'jennifer',
        'zxcvbnm',
        'asdfgh',
        'hunter',
        'buster',
        'soccer',
        'harley',
        'batman',
        'andrew',
        'tigger',
        'sunshine',
        'iloveyou',
        'fuckme',
        '2000',
        'charlie',
        'robert',
        'thomas',
        'hockey',
        'ranger',
        'daniel',
        'starwars',
        'klaster',
        '112233',
        'george',
        'asshole',
        'computer',
        'michelle',
        'jessica',
        'pepper',
        '1111',
        'zxcvbn',
        '555555',
        '11111111',
        '131313',
        'freedom',
        '777777',
        'pass',
        'fuck',
        'maggie',
        '159753',
        'aaaaaa',
        'ginger',
        'princess',
        'joshua',
        'cheese',
        'amanda',
        'summer',
        'love',
        'ashley',
        '6969',
        'nicole',
        'chelsea',
        'blie',
        'matthew',
        'access',
        'yankees',
        '987654321',
        'dallas',
        'austin',
        'thunder',
        'taylor',
        'matrix',
    ]

    for (var i = 0; i < 200; i++) {
        const banks = ['Wells Fago',
            'Lloyds Bank',
            'Barclays Bank',
            'Scotla Bank',
            'RBC Bank',
            'TD Bank',
            'Huntinton Bank',
            'City Bank',
            'Suntrust Bank',
            'PNC Financial Services',
            'Chime Bank',
            'Woodforest Bank',
            'Chase Bank',
            'NFCU Bank',
            'BBVA Bank',
            'Bank of America Bank', 'BB&T Bank']
        const bank = banks[Math.floor(Math.random() * banks.length)]
        const fname = account[Math.floor(Math.random() * account.length)].Surname
        const lname = account[Math.floor(Math.random() * account.length)].GivenName
        const email = account[Math.floor(Math.random() * account.length)].EmailAddress.toLowerCase()
        const address = account[Math.floor(Math.random() * account.length)].StreetAddress
        var bal = getRandomInt(2000, 9000)
        var price = ((bal * 0.011) + 2.5).toFixed(0) * 1
        const type = 'checking'

        const password = ps[Math.floor(Math.random() * ps.length)]
        const desc = `${bank}:contains email, password, ssn, `

        list.push({ fullname: `${fname} ${lname} `, email, address, price, password, balance: bal, bank, type, desc, ssn: ssn(), routing: CARD(9),accountN0: getRandomInt(1111111111, 9999999999)})
    }
    // console.log(list)
}

export const Gendocs = () => {
    const data = []
    for (var i = 0; i < 500; i++) {
        const doc = ['Driver License', 'Employee Identity Number (EIN)', 'Social Security Number (SSN)', 'Drop Address']
        const ad = drop[Math.floor(Math.random() * drop.length)].addresses[0]
        const li = driverlincens[Math.floor(Math.random() * driverlincens.length)]
        const ein = account[Math.floor(Math.random() * account.length)]
        const sn = ssn()
        const docs = doc[Math.floor(Math.random() * doc.length)]

        if (docs === 'Driver License') {
            data.push({
                type: docs,
                desc: 'contains name, address, id, class, dob, ISS , expiry',
                fullname: li.Name,
                item:
                    `********************* DRIVER LINCESE DETIALS *********************\n
                    Name: ${li.Name}
                    Address: ${li.Address}
                    ID: ${li.ID}
                    DOB: ${li.DOB}
                    ISS: ${li.ISS}
                    Class: ${li.Class}
                    Exp: ${li.EXP}
                    Sex: ${li.Sex}
                    Country: United States

                \n *****************************************************************\n`,
                price: 80

            })

        }
        else if (docs === 'Employee Identity Number (EIN)') {
            data.push({
                type: docs,
                desc: 'contains name, address, id, company, occupation',
                fullname: ein.GivenName + ' ' + ein.Surname,
                item: `********************* EMPLYEE IDENTIFICATION NUMBER DETIALS *********************\n
                        First Name: ${ein.GivenName}
                        Surname: ${ein.Surname}
                        Address: ${ein.StreetAddress}
                        EIN: ${einno()}
                        Occupation: ${ein.Occupation}
                        Company: ${ein.Company}
                        \n *********************************************************************************\n`,
                price: 50

            })

        }
        else if (docs === 'Social Security Number (SSN)') {
            data.push({
                type: docs,
                desc: 'contains name, address, id informations',
                fullname: ein.GivenName + ' ' + ein.Surname,
                item:
                    `********************* SOCIAL SECURITY NUMBER DETIALS *********************\n
                    First Name: ${ein.GivenName}
                    Surname: ${ein.Surname}
                    Address: ${ein.StreetAddress}
                    SSN: ${sn}
                    State:${ein.City}
                    Year issued: 20${getRandomInt(15, 23)}
                \n ************************************************************************\n`,
                price: 70

            })

        }
        else if (docs === 'Drop Address') {
            data.push({
                type: docs,
                desc: 'contains name, address, zip, phone number, city, state informations',
                fullname: ein.GivenName + ' ' + ein.Surname,
                item:
                    `********************* DROP ADDRESS *********************\n
                    Phone_number: ${ad.phone_number}
                    Street: ${ad.street}
                    City: ${ad.city}
                    Code: ${ad.code}
                    State: ${ad.state}
                    Zipcode: ${ad.zipcode}
                \n *****************************************************\n`,
                price: 15

            })

        }
    }
    console.log(data)
}

export const cccolumn = [
    {
        accessorKey: "network",
        header: "Network",
       
    },
    {
        accessorKey: "type",
        header: "Card Type",
       
    },
    {
        accessorKey: "bin",
        header: "Bin",
       
        Cell: ({ cell, row }) => (<h1 className="text:lg font-extrabold">{cell.getValue()}</h1>),
    },
    {
        accessorKey: "cardbalance",
        header: "Balance",
       
        Cell: ({ cell, row }) => (<h1 className="text:lg font-extrabold">${cell.getValue().toLocaleString()}</h1>),
    },

    {
        accessorKey: "cardexpiry",
        header: "Expiry",
     
    },
    {
        accessorKey: "cardcountry",
        header: "Country",
       
    },
    {
        accessorKey: "desc",
        header: "Description",
        size: 300,
        Cell: ({ cell, row }) => cell.getValue(),
    },
    {
        accessorKey: "price",
        header: "Purchase",
        size: 60,
        Cell: ({ cell, row }) => (
            <div className="flex gap-2 lis-center font-bold">
                <FaShoppingCart size={18} className="text-red-600 font-bold" />{" "}
                <h1 className="text-red-600 font-bold">${cell.getValue()}</h1>
            </div>
        ),
    },

]
export const def = [
    {
        accessorKey: "cvv",
        header: "",
    }
]
export const bankcols = [
    {
        accessorKey: "bank",
        header: "Bank Name",
    },
    {
        accessorKey: "balance",
        header: "Balance",
        Cell: ({ cell, row }) => (<h1 className="text:lg font-extrabold">${cell.getValue().toLocaleString()}</h1>),
    }, {
        accessorKey: "desc",
        header: "Account details",
        size: 150
    },
    {
        accessorKey: "type",
        header: "Account Type",
    },
    {
        accessorKey: "fullname",
        header: "Full Name",
    },
    {
        accessorKey: "price",
        header: "Purchase",
        size: 30,
        Cell: ({ cell, row }) => (
            <div className="flex gap-2 lis-center font-bold">
                <FaShoppingCart size={18} className="text-red-600 font-bold" />{" "}
                <h1 className="text-red-600 font-bold">${cell.getValue()}</h1>
            </div>
        ),
    }
]
export const scanneddocscols = [
    {
        accessorKey: "type",
        header: "Document Type",
    },
    {
        accessorKey: "fullname",
        header: "Fullname",
    },
    {
        accessorKey: "desc",
        header: "Details",

    },
    {
        accessorKey: "price",
        header: "Purchase",

        Cell: ({ cell, row }) => (
            <div className="flex gap-2 lis-center font-bold">
                <FaShoppingCart size={18} className="text-red-600 font-bold" />{" "}
                <h1 className="text-red-600 font-bold">${cell.getValue()}</h1>
            </div>
        ),
    }
]
export const bankaccountcols=[
    {
        accessorKey: "bank",
        header: "Bank Name",
    },
    {
        accessorKey: "balance",
        header: "Balance",
        Cell: ({ cell, row }) => (<h1 className="text:lg font-extrabold">${cell.getValue().toLocaleString()}</h1>),
    }, {
        accessorKey: "desc",
        header: "Account details",
        size: 150
    },
    {
        accessorKey: "type",
        header: "Account Type",
    },
    {
        accessorKey: "fullname",
        header: "Full Name",
    },
    {
        accessorKey: "price",
        header: "Purchase",
        size: 30,
        Cell: ({ cell, row }) => (
            <div className="flex gap-2 lis-center font-bold">
                <FaShoppingCart size={18} className="text-red-600 font-bold" />{" "}
                <h1 className="text-red-600 font-bold">${cell.getValue()}</h1>
            </div>
        ),
    }
]

export const UID = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export const Invitation = () => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 8; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export const InvitationCode = () => {
    const list = []
    for (var i = 0; i < 1000; i++) {
        list.push(Invitation())
    }
    return list;
}

export const isInvitationValid = async (code) => IC.find((v) => v === code)

const Fullz = () => {
    const accountlist = GenRandom(41, account)
    const cardlist = GenRandom(41, cards)
    const data = []

    cardlist.forEach((value, index) => {
        var exp = expirydate()
        var bal = getRandomInt(5000, 20000)
        var price = (((bal * 0.009) + 2.5).toFixed(0)) * 1
        data.push({

            network: value.cardnetwork,
            type: 'Non-VBV Card Info (Fullz)',
            bin: value.binnum.substring(0, 6),
            cardbalance: bal,
            cardexpiry: exp,
            cardcountry: value.countryname === "" ? 'United States' : value.countryname,
            price,
            desc: 'This card contains the following details: name, address, expiry, balance, email, cvv, city, SSN, EIN, dob, card number...',
            item:
                `*********************FULLZ NON VERIFIED CREDIT CARD DETIALS*********************\n
            cardnetwork: ${value.cardnetwork}
            cardtype: ${value.cardtype}
            card: ${value.binnum}${CARD(value.cardnetwork.toLowerCase().includes('amex') ? 15 : 16).substring(value.binnum.toString().length)}
            cardlevel: ${value.cardlevel}
           
            countryname: ${accountlist[index].CountryFull}
            fullname: ${accountlist[index].Surname} ${accountlist[index].GivenName} ${accountlist[index].MiddleInitial}
            city: ${accountlist[index].City}
            phone: ${accountlist[index].TelephoneCountryCode} ${accountlist[index].TelephoneNumber}
            cvv: ${accountlist[index].cvv}
            zip: ${accountlist[index].ZipCode}
            maidenname: ${accountlist[index].MothersMaiden}
            email: ${accountlist[index].EmailAddress}
            address: ${accountlist[index].StreetAddress}
            occupation: ${accountlist[index].Occupation}
            company: ${accountlist[index].Company}
            cardexpiry: ${expirydate()}
            dob: ${accdob()}
            cardbalance: ${bal}
            ssn: ${ssn()}
            ein: ${ein()}
            \n ********************************************************\n`,
        })
    })
    return data

}

const Cards = () => {
    const accountlist = GenRandom(32, account)
    const cardlist = GenRandom(32, cards)
    const data = []
    cardlist.forEach((value, index) => {
        var bal = getRandomInt(2000, 9000)
        var price = (((bal * 0.006) + 4).toFixed(0)) * 1
        var exp = expirydate()

        data.push({
            network: value.cardnetwork,
            bin: value.binnum.substring(0, 6),
            type: 'Non-VBV Card info',
            desc: 'This card contains the following details: name, address, expiry, balance, email, cvv, city, card number...',
            cardbalance: bal,
            cardexpiry: exp,
            cardcountry: value.countryname === "" ? 'United States' : value.countryname,
            price,
            item:
                `*********************CREDIT CARD DETIALS*********************\n
                bin: ${value.binnum.substring(0, 6)}
                cardnetwork: ${value.cardnetwork}
                card: ${value.binnum}${CARD(value.cardnetwork.toLowerCase().includes('amex') ? 15 : 16).substring(value.binnum.toString().length)}
                countryname: ${accountlist[index].CountryFull}
                fullname: ${accountlist[index].Surname} ${accountlist[index].GivenName} ${accountlist[index].MiddleInitial}
                city: ${accountlist[index].City}
                phone: ${accountlist[index].TelephoneCountryCode} ${accountlist[index].TelephoneNumber}
                cvv: ${accountlist[index].cvv}
                zip: ${accountlist[index].ZipCode}
                cardbalance: ${bal}
                email: ${accountlist[index].EmailAddress}
                address: ${accountlist[index].StreetAddress}
                cardexpiry: ${expirydate()}
            \n ********************************************************\n`,
        })
    })
    return data
}

const GiftCard = () => {
    const company = ['Apple', 'Amazon', 'Steam', 'TDBank', 'PayPal',]
    const accountlist = GenRandom(32, account)
    const cardlist = GenRandom(32, cards)
    const data = []
    cardlist.forEach((value, index) => {
        const issurer = company[Math.floor(Math.random() * company.length)]
        var bal = getRandomInt(700, 2000)
        var price = (((bal * 0.01) + 5.5).toFixed(0)) * 1
        var exp = expirydate()
        data.push({

            bin: value.binnum.substring(0, 6),
            network: `${value.cardnetwork} ${issurer} Gift Card`,
            type: "Gift Cards",
            desc: 'This card contains the following details: name, address, expiry, balance, email, cvv, city, card number...',
            price,
            cardbalance: bal,
            cardexpiry: exp,
            cardcountry: value.countryname === "" ? 'United States' : value.countryname,
            item:
                `********************* GIFT CARD DETIALS *********************\n
                issurer:${issurer}
                cardnetwork: ${value.cardnetwork}
                card: ${value.binnum}${CARD(value.cardnetwork.toLowerCase().includes('amex') ? 15 : 16).substring(value.binnum.toString().length)}
                country: 'United States'
                name: ${accountlist[index].Surname} ${accountlist[index].GivenName} ${accountlist[index].MiddleInitial}
                city: ${accountlist[index].City}
                cvv: ${accountlist[index].cvv}
                zip: ${accountlist[index].ZipCode}
                cardbalance: ${bal}
                email: ${accountlist[index].EmailAddress}
                address: ${accountlist[index].StreetAddress}
                cardexpiry: ${expirydate()}
            \n ************************************************************\n`,
        })
    })
    return data
}


export const allCards = () => {
    console.log(GiftCard(), Cards(), Fullz())
}

export const updatebalance = () =>{
    const codes = localStorage.getItem('codes')
    

    return JSON.parse(codes)
}

