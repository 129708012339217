import { useLayoutEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import 'tw-elements';
import './App.css'
import Explorer from './pages/explorer';
import HomePage from './pages/homepage';
import { mainhome, althome, category, creditcardshop, auth, about, paymentshop, bankaccountshop, banklogshop, scanneddocshop, anonymousshop, socialmediashop, shoppingshop, cryptoshop, tutorialsshop, chequesshop, account } from './config/data';
import CategoryPage from './pages/categorypage';
import PageNotFound from './pages/notfound';
import CreditCardPage from './pages/creditcardpage';
import AuthPage from './pages/authpage';
import AboutPage from './pages/aboutpage';
import PaymentGateway from './payments/paymentsniffer';
import BanklogPage from './pages/banklogpage';
import ScannedDocumentPage from './pages/scanneddocpage';
import BankAccountPage from './pages/bankaccountpage';
import AnonymityPage from './pages/anonymitypage';
import SocialMediaPage from './pages/socialmediapage';
import ShoppingScriptPage from './pages/shoppingscriptPage';
import TutorialPage from './pages/tutorialpage';
import BankChequePage from './pages/bankchequepage';
import CryptocurrencyPage from './pages/cryptocurrencypage';
import AccountPage from './pages/accountpage';




const App = () => {

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  }
  return (
    <div className="flex justify-center">
      <BrowserRouter >
        <Wrapper>
          <div className="flex w-full justify-center">
            <Routes>
              <Route path={mainhome} element={< HomePage />} loading />
              <Route path={althome} element={< HomePage />} loading />
              <Route path={category} element={< CategoryPage loading />} />
              <Route path={creditcardshop} element={< CreditCardPage />} loading />
              <Route path={auth} element={< AuthPage />} loading />
              <Route path={about} element={< AboutPage />} loading />
              <Route path={account} element={< AccountPage />} loading />
              <Route path={paymentshop} element={< PaymentGateway />} loading />
              <Route path={banklogshop} element={< BanklogPage />} loading />
              <Route path={scanneddocshop} element={< ScannedDocumentPage />} loading />
              <Route path={bankaccountshop} element={< BankAccountPage />} loading />
              <Route path={anonymousshop} element={<  AnonymityPage />} loading />
              <Route path={socialmediashop} element={< SocialMediaPage />} loading />
              <Route path={shoppingshop} element={< ShoppingScriptPage />} loading />
              <Route path={cryptoshop} element={< CryptocurrencyPage />} loading />
              <Route path={tutorialsshop} element={< TutorialPage />} loading />
              <Route path={chequesshop} element={< BankChequePage />} loading />
              <Route path="/tx/:slug" element={< Explorer />} />

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        </Wrapper>
      </BrowserRouter>
    </div>
  );
};

export default App;
